import {
  Avatar,
  Button,
  Divider,
  IndexPath,
  Input,
  Layout,
  List,
  ListItem,
  Modal,
  Select,
  SelectItem,
  Spinner,
  Text,
  Tooltip,
  ViewPager,
} from '@ui-kitten/components';
import * as DocumentPicker from 'expo-document-picker';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, Platform, ScrollView, StyleSheet, View } from 'react-native';
// import Icon from 'react-native-vector-icons/FontAwesome5';
import IconIon from 'react-native-vector-icons/Ionicons';
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-native';
import { authContext } from '../../hooks/authentication';
import { signContext } from '../../hooks/signingprocess';
import { downloadFile as dlFile, getFile, getNameFile, uploadFile } from '../../services/files';
import { digestMessage, getLumenInfo, getSign, getSpecLine, updateSigningProcess } from '../../services/signingprocess';
import {
  DownIcon,
  EjectIcon,
  EjectSignIcon,
  ExelFileIcon,
  ImageIcon,
  PdfFileIcon,
  PowerPointIcon,
  UnknownFileIcon,
  UpIcon,
  WordFileIcon,
} from '../../share/filter-icon';
import { getAvatarColor } from '../../utils/avatar';
import { canOpen, isPdf } from '../../utils/files';
// import { isChromeAndroid } from '../../utils/browser';
import Svg, { Path } from 'react-native-svg';
import { Animated } from 'react-native';
import { isTypePheDuyet } from '../../utils/function';
import { getBaseApiUrl } from '../../config';

const AddIcon = (props) => (
  <Svg width={12} height={14} viewBox="0 0 448 512" style={styles.menuIcon}>
    <Path
      d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
      fill="#636e72"
    />
  </Svg>
);

const LayerIcon = (props) => (
  <Svg width={18} height={14} viewBox="0 0 576 512" style={styles.menuIcon}>
    <Path
      d="M464 4c-11 0-20 9-20 20V60H408c-11 0-20 9-20 20s9 20 20 20h36v36c0 11 9 20 20 20s20-9 20-20V100h36c11 0 20-9 20-20s-9-20-20-20H484V24c0-11-9-20-20-20zM288 128c-8.5 0-17 1.7-24.8 5.1L53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 133.1c-7.8-3.4-16.3-5.1-24.8-5.1zm-5.9 49.2c1.9-.8 3.9-1.2 5.9-1.2s4 .4 5.9 1.2L477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256l183.8-78.8zM85.1 337.4L53.9 350.8C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4z"
      fill="#e84393"
    />
  </Svg>
);

const ShieldIconTitle = (props) => (
  <Svg width={16} height={14} viewBox="0 0 640 512" style={styles.menuIcon}>
    <Path
      d="M243.5 37.3c8-3.4 17-3.4 25 0l176.7 75c11.3 4.8 18.9 15.5 18.8 27.6c-.5 94-39.4 259.8-195.5 334.5c-7.9 3.8-17.2 3.8-25.1 0C87.3 399.6 48.5 233.8 48 139.8c-.1-12.1 7.5-22.8 18.8-27.6l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zm82.3 195.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z"
      fill="#FFFFFF"
    />
  </Svg>
);
const ShieldIcon = (props) => <IconIon {...props} style={styles.icon} />;

const FlagIcon = (props) => (
  <View
    style={{
      paddingLeft: 3,
      paddingRight: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderRadius: 90,
      width: 8,
      height: 8,
      borderColor: props.check === 'true' ? '#524f4f' : '#BDBDBD',
      backgroundColor: props.check === 'true' ? '#524f4f' : '#FFFFFF',
    }}
  />
);

const AvatarImage = (props) => {
  // const auth = useContext(authContext);
  const avatarUrl = getBaseApiUrl() + "/" + props.avatar;
  const name = props.name ? props.name?.toUpperCase()?.split(' '): null;
  const avtname = props.avatarname ? props.avatarname?.toUpperCase()?.split(' '): null;
   
  const imageName = props.name
    ? props.name?.search('G26') >= 0 ? 'LT' : name?.at(0)?.at(0) + (name?.at(1)?.at(0) != undefined ? name?.at(1)?.at(0) : '')
    : props?.firstname
    ? props?.firstname?.at(0) + props?.lastname?.at(0)
    : avtname?.at(0)?.at(0) + avtname?.at(1)?.at(1);

  if (props.avatar) return <Avatar size={props.size ? props.size : 'medium'} source={{ uri: avatarUrl }} />;
  else
    return (
      <View
        style={{
          height: 40,
          width: 40,
          backgroundColor: getAvatarColor(imageName === 'LT' ? props.name : imageName),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 50,
        }}
      >
        <Text style={{ color: '#ffffff', fontWeight: '600' }}>{imageName}</Text>
      </View>
    );
};

export default (props) => {
  const navigate = useNavigate();
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [isShowFile, setIsShowFile] = React.useState(true);
  const [isShowCollapse, setIsShowCollapse] = React.useState(false);
  const [visibleIsSequentialRight, setVisibleIsSequentialRight] = React.useState(false);
  const [visibleIsSequentialLeft, setVisibleIsSequentialLeft] = React.useState(false);

  const showWaiting = () => {
    setIsWaiting(true);
    setTimeout(() => setIsWaiting(false), 500);
  };
  const auth = useContext(authContext);
  const { units, cohorts } = useContext(authContext);
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const data = ['Chọn hướng xử lý', 'Xét duyệt TC', 'LĐ.VP duyệt'];
  const displayValue = data[selectedIndex.row];
  const [nextStep, setNextStep] = React.useState(3);
  const [isTravel, setIsTravel] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState({ isTrue: false, isShow: false, action: null, index: -1, isClone: false });

  useEffect(() => {
    if (selectedIndex.row === 1) setNextStep(3);
    else if (selectedIndex.row === 2) setNextStep(4);
  }, [selectedIndex.row]);

  const [isShowProcess, setIsShowProcess] = React.useState(false);

  const [specId, setSpecId] = React.useState({ flag: false, data: null });
  const state = useLocation();

  useEffect(() => {
    if (specId.flag) return;
    specId.data = state?.pathname?.slice(8, 50);
    specId.flag = true;
    if (state != null) setSpecId({ ...specId });
  }, [specId, specId.flag]);

  const getLumen = async ({ queryKey }) => {
    if (!queryKey) return null;

    const [_, prodId] = queryKey;
    return await getLumenInfo(prodId);
  };

  const rootSpec = useQuery([`lumeninfo`, specId.data], getLumen);
  const [SpecLine, setSpecLine] = React.useState({ flag: false, data: null });

  useEffect(() => {
    if (rootSpec.isFetching) return;
    showWaiting();
    setIsShowProcess(false);
    selectedIndexView.flag = false;
    setSelectedIndexView({ ...selectedIndexView });
    if (SpecLine.flag) return;
    if (rootSpec.data?.at(0)?.specId === null || rootSpec.data?.at(0)?.specId === undefined) return;
    getSpecLine(rootSpec.data?.at(0)?.specId).then((data) => {
      SpecLine.flag = true;
      SpecLine.data = data;
      setSpecLine({ ...SpecLine });
    });
  }, [rootSpec.isFetched, rootSpec.isFetching]);
  const sign = useContext(signContext);
  const [isShowCancel, setShowCancel] = React.useState(true);

  useEffect(() => {
    if (rootSpec.data === undefined || rootSpec.data === null) return;
    if (rootSpec.isFetching) return;
    setIsPending(false);
    setIsTravel(false);
    setNextStep(3);
    setVisibleIsSequentialRight(false);
    setVisibleIsSequentialLeft(false);
    setDataTimeLine(rootSpec.data?.at(0)?.progresses);
    setDataSpec(rootSpec.data?.at(0));
    const actionlast = rootSpec.data?.at(0)?.progresses?.at(rootSpec.data?.at(0)?.progresses?.length - 1);
    for(const progress of rootSpec.data?.at(0)?.progresses){
      if(progress.step > 2) {
        setShowCancel(false)
        break;
      }
    }

    setSpecName('Lumen');
    setPactName(rootSpec.data?.at(0)?.name);
    if (rootSpec.data?.at(0)?.status === 'cancelled' && actionlast?.step === 0) {
      sign.setStatus({ color: '#636e72', tag: 'Hủy' });
    } else if (rootSpec.data?.at(0)?.status === 'cancelled') {
      sign.setStatus({ color: '#636e72', tag: 'Trả hồ sơ' });
    } else if (actionlast?.status === 'done') {
      sign.setStatus({ color: '#575fcf', tag: 'Hoàn tất' });
    } else if (
      actionlast?.status === 'pending' &&
      actionlast?.name === 'Ban GĐ ký' &&
      actionlast?.actions?.find(action=>action?.actorId ==='' && action?.tag === '_boss' && isTypePheDuyet('CQ', action?.candidateType, action?.candidateId, auth.user._id, auth.units, auth.cohorts)) != undefined
    ) {
      sign.setStatus({ color: '#0984e3', tag: 'Chờ phê duyệt' });
    } else {
      sign.setStatus({ color: '#e84393', tag: 'Đang xử lý' });
    }
  }, [rootSpec.isFetched, rootSpec.isFetching]);
  const [dataSpec, setDataSpec] = React.useState(null);
  const [tempSpec, setTempSpec] = React.useState(null);
  const [digitalSignFile, setDigitalSignFile] = React.useState({ refFileIds: [], signFileIds: [] });

  useEffect(() => {
    if (dataSpec?.pendingForUsers?.length > 0 && dataSpec.pendingForUsers?.find((userId) => userId === auth.user._id)) setIsPending(true);
    else if (
      dataSpec?.pendingForUnits?.length > 0 &&
      dataSpec.pendingForUnits?.find(
        (punit) =>
          units?.find((unit) => unit._id === punit && unit.members?.find((user) => user === auth.user._id) != undefined) != undefined
      )
    )
      setIsPending(true);
    else if (
      dataSpec?.pendingForCohorts?.length > 0 &&
      dataSpec.pendingForCohorts?.find(
        (pcohort) =>
          cohorts?.find((cohort) => cohort._id === pcohort && cohort.members?.find((user) => user === auth.user._id) != undefined) !=
          undefined
      )
    )
      setIsPending(true);
  }, [dataSpec, cohorts, units, auth, auth.user, auth.users]);

  const [dataTimeLine, setDataTimeLine] = React.useState(null);
  const [dataTimeLineProcess, setDataTimeLineProcess] = React.useState(null);
  const [pactName, setPactName] = React.useState('');
  const [specName, setSpecName] = React.useState('');
  const [tag, setTag] = React.useState('');
  const [tagColor, setTagColor] = React.useState('');
  const [dvNgoai, setdvNgoai] = React.useState('');
  const [leftList, setLeftList] = React.useState(null);
  const [rightList, setRightList] = React.useState({ process: false, data: null });
  const [conclusion, setConclusion] = React.useState('');
  const [isPending, setIsPending] = React.useState(false);
  const [isShowWait, setIsShowWait] = React.useState(false);
  const [isShowTL0, setIsShowTL0] = React.useState(false);
  const [isShowRequest, setIsShowRequest] = React.useState(true);
  const [isKyThua, setIsKyThua] = React.useState(false);
  const [textWait, setTextWait] = React.useState('');

  const ShieldFileIcon = () => (
    <IconIon
      style={{
        marginLeft: 0,
        marginRight: 0,
        paddingTop: 0,
        paddingLeft: -2,
        fontSize: 15,
        minWidth: 20,
        textAlign: 'center',
        ...props.style,
      }}
      solid
      name="shield-checkmark-outline"
      color="#0969da"
    ></IconIon>
  );
  const getFileOrFolderIcon = (originalName: string) => {
    const fileName = (originalName || '').toLowerCase();

    if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
      return <WordFileIcon></WordFileIcon>;
    } else if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
      return <PowerPointIcon></PowerPointIcon>;
    } else if (fileName.endsWith('.pdf')) {
      return <PdfFileIcon></PdfFileIcon>;
    } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
      return <ExelFileIcon></ExelFileIcon>;
    } else if (
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.gif') ||
      fileName.endsWith('.psd') ||
      fileName.endsWith('.ai')
    ) {
      return <ImageIcon></ImageIcon>;
    } else {
      return <UnknownFileIcon></UnknownFileIcon>;
    }
  };

  const checkUserInclude = (process, curUserId, unitList, cohortList, type) => {
    if (
      cohortList?.find(
        (cohort) => cohort?._id === '_task_approvers_biz' && cohort?.members?.find((id) => id === auth.user._id) != undefined
      ) != undefined
    )
      return true;
    let processType = type.toLowerCase();
    let arr_skip_step = [0, 1];

    if (['pact'].includes(processType)) {
      arr_skip_step = [0, 1];
    }

    if (['fuse', 'dfuse'].includes(processType)) {
      arr_skip_step = [0, 1];

      if ('_hyperspace' == process?.applicantId || '_gov' == process?.applicantId || 'Fuse-external' == process?.specName) {
        arr_skip_step = [];
      }
    }

    if (['lumen', 'dlumen'].includes(processType)) {
      arr_skip_step = [0, 1, 7];
    }

    if (['elumen'].includes(processType)) {
      arr_skip_step = [6];
    }

    for (var i = 0; i < process?.progresses?.length; i++) {
      let progress = process?.progresses[i];

      if (arr_skip_step?.includes(progress?.step)) continue;

      for (var mm = 0; mm < progress?.actions?.length; mm++) {
        let action = progress?.actions?.at(mm);
        if (action?.candidateType === 'user' && action?.candidateId === curUserId) {
          return true;
        }
        if (action?.candidateType === 'unit') {
          let unit = unitList?.find((unit) => unit._id === action.candidateId);
          if (unit?.members?.find((id) => id === curUserId) != undefined) return true;
        }
        if (action?.candidateType === 'cohort') {
          let cohort = cohortList?.find((cohort) => cohort._id === action.candidateId);
          if (cohort?.members?.find((id) => id === curUserId) != undefined) return true;
        }
      }
    }

    return false;
  };

  const getDirection = (progress) => {
    let direction = progress?.actions?.at(0).requested;
    for (var i = 0; i < progress?.actions?.length; i++) {
      let a = progress?.actions?.at(i);
      if (a?.role === 'primary' && a?.direction != '' && a.direction != a.requested) direction = a.direction;
    }
    return direction;
  };

  const [isXetDuyetTC, setIsXetDuyetTC] = React.useState(true);
  const [isHaveXetDuyetTC, setIsHaveXetDuyetTC] = React.useState(false);
  const [isBGD, setBGD] = React.useState(true);
  const [isNVB, setNVB] = React.useState(true);
  const [isNHS, setNHS] = React.useState(false);
  const [Incantation, setIncantation] = React.useState([]);
  const [Appendix, setAppendix] = React.useState<any>(null);
  const [AppendixProcess, setAppendixProcess] = React.useState({ documentNumber: '', signatory: '' });

  const [placeholderText, setPlaceholderText] = React.useState('Phản hồi lý do (từ chối), hoặc nội dung trình tiếp (duyệt)');
  const refScrollView = React.useRef<ScrollView>(null);

  const [mapFileNames, setMapFileNames] = useState({});

  const [waiting, setWaiting] = React.useState(false);

  const [selectedIndexView, setSelectedIndexView] = React.useState({ index: 0, flag: false });

  const changeScrollViewtoBottom = () => {
    setTimeout(() => refScrollView.current?.scrollToEnd({ animated: true }), 500);
  };
  useEffect(() => {
    if (dataTimeLine === null || dataTimeLine === undefined) return;

    setTimeout(async () => {
      for (let index = 0; index < dataTimeLine?.length; index++) {
        const item = dataTimeLine[index];
        for (let fileIndex = 0; fileIndex < item?.refFileIds.length; fileIndex++) {
          if (!!mapFileNames[item.refFileIds.at(fileIndex)]) continue;

          const file = await getFile({ id: item.refFileIds.at(fileIndex) });
          mapFileNames[item.refFileIds.at(fileIndex)] = {
            name: file?.name,
            notarizations: file?.notarizations && file?.notarizations?.length > 0 ? true : false,
          };
        }

        for (let fileIndex = 0; fileIndex < item?.signFileIds.length; fileIndex++) {
          if (!!mapFileNames[item.signFileIds.at(fileIndex)]) continue;

          const file = await getFile({ id: item.signFileIds.at(fileIndex) });
          mapFileNames[item.signFileIds.at(fileIndex)] = {
            name: file?.name,
            notarizations: file?.notarizations && file?.notarizations?.length > 0 ? true : false,
          };
        }

        for (let fileIndex = 0; fileIndex < item?.supFileIds.length; fileIndex++) {
          if (!!mapFileNames[item.supFileIds.at(fileIndex)]) continue;
          const file = await getFile({ id: item.supFileIds.at(fileIndex) });
          mapFileNames[item.supFileIds.at(fileIndex)] = {
            name: file?.name,
            notarizations: false,
          };
        }
      }
      setMapFileNames({ ...mapFileNames });
    }, 0);
  }, [dataTimeLine]);

  useEffect(() => {
    if (dataTimeLine === null || dataTimeLine === undefined) return;
    if (Object.keys(mapFileNames).length === 0) return;
    if (units === null) return;
    if (cohorts === null) return;
    if (auth.user === null) return;
    if (auth.users === null) return;
    if (auth.users?.length <= 0) return;
    if (dataSpec === null) return;
    const data = dataTimeLine?.map(async (item, index, array) => {
      item.actions = await item.actions?.map(async (itemAction, indexAction, arrayAction) => {
        itemAction.actionIndex = indexAction;
        if (indexAction === 0) {
          item.isSequential = undefined;
          item.end = undefined
        } 
        if (itemAction.direction === 'submit') {
          itemAction.process = 'Nộp hồ sơ';
          itemAction.processcolor = '#198753';
          if (item.flag === undefined) item.flag = 1;
        } else if (itemAction.direction === 'approve') {
          itemAction.process = 'Đã duyệt';
          itemAction.processcolor = '#198753';
          if (item.flag === undefined) item.flag = 1;
        } else if (itemAction.direction === 'sign') {
          itemAction.process = 'Đã ký';
          itemAction.processcolor = '#5b5fc7';
          if (item.flag === undefined) item.flag = 2;
        } else if (itemAction.direction === 'reset') {
          itemAction.process = 'Yêu cầu hiệu chỉnh';
          item.flag = 0;
          itemAction.processcolor = '#e58e26';
        } else if (itemAction.direction === 'reject') {
          itemAction.process = 'Từ chối';
          item.flag = 0;
          itemAction.processcolor = '#e58e26';
        } else if (itemAction.direction === 'cancel' && item?.name === 'Nộp hồ sơ') {
          itemAction.process = 'Hủy';
          item.flag = 0;
          itemAction.processcolor = '#dc3545';
        } else if (itemAction.direction === 'cancel') {
          itemAction.process = 'Trả hồ sơ';
          item.flag = 0;
          itemAction.processcolor = '#e58e26';
        } else if (itemAction.direction === 'confirm') {
          itemAction.process = 'Xác nhận';
          itemAction.processcolor = '#198753';
          if (item.flag === undefined) item.flag = 1;
        } else {
          itemAction.process = 'Chờ xử lý';
          item.flag = -1;
        }

        if (item.isSequential || (item?.status === 'pending' && item?.actions?.find(action => action?.role === 'sequential') != undefined)) {
          itemAction.isSequential = true;
          item.isSequential= true;
          if (item?.end) {
            itemAction.isShow = false;
          }
          else if (itemAction?.direction === '') {
            itemAction.isShow = true;
            item.end = true
          }
          else if (itemAction?.actorId != '') {
            itemAction.isShow = true;
          }
          else {
            itemAction.isShow = false;
          }
        } else {
          itemAction.isSequential = false;
        }

        if (
          itemAction?.incantation &&
          (((index === array?.length - 2 ||
            index === array?.length - 3 ||
            (array?.at(array?.length - 1)?.name === 'Nộp hồ sơ' && array?.at(array?.length - 1)?.status === 'pending')) &&
            array?.at(index)?.status === 'done' &&
            (array?.at(index + 1)?.status === 'pending' ||
              array?.at(index + 2)?.status === 'pending' ||
              (array?.at(array?.length - 1)?.name === 'Nộp hồ sơ' && array?.at(array?.length - 1)?.status === 'pending'))) ||
            (array?.at(array?.length - 1)?.status === 'pending' && index === array?.length - 1))
        ) {
          let te = JSON.parse(itemAction.incantation);
          item.te = te;
        }
        if (item.name === 'Nhận văn bản' && item.status === 'pending') item.flag = -1;
        else if (item.name === 'Nhận văn bản') item.flag = 3;

        if (item.status === 'done') {
          if (itemAction?.actorId === '_hyperspace') {
            const unit = units?.find((unit) => unit._id === itemAction.hostId);
            if (unit != undefined) {
              itemAction.flagProcess = false;
              itemAction.name = unit?.govId;
            }
            else {
              itemAction.flagProcess = false;
              itemAction.name = 'G26.2.02';
            }
          } else {
          const user = auth.users?.find((user) => user._id === itemAction.actorId);
          const name = user?.fullname?.split(' ');
          itemAction.name = name?.at(name?.length - 1) + ' ' + name?.at(0);
          itemAction.flagProcess = false;
          if (user?.avatar) {
            itemAction.avatar = user.avatar;
          } else {
            itemAction.firstname = auth.users?.find((user) => user._id === itemAction.actorId)?.firstname;
            itemAction.lastname = auth.users?.find((user) => user._id === itemAction.actorId)?.lastname;
          }
          }
          const time = new Date(itemAction?.atTime);
          const month = time.toLocaleString('en-US', { month: '2-digit' });
          itemAction.timeprocess =
            time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) + ', ' + time.getDate() + ' tháng ' + month;
        } else if (itemAction.candidateType === 'user') {
          const user = auth.users?.find((user) => user._id === itemAction.candidateId);
          const name = user?.fullname?.split(' ');
          if (itemAction.candidateId === auth.user._id && itemAction.actorId === '') {
            itemAction.flagProcess = true;
          } else {
            itemAction.flagProcess = false;
          }
          const unit = units?.find((unit) => unit._id === user?.conscriptions?.at(0).unitId);
          itemAction.name = name?.at(name?.length - 1) + ' ' + name?.at(0);
          if (item.flag === -1 && itemAction.direction === '' && itemAction?.candidateId === auth.user._id) {
            item.avatarname = name?.at(name?.length - 1) + ' ' + name?.at(0);
            item.unitcode = unit?.code;
            if (user?.avatar) item.avatar = user.avatar;
            else item.avatar = undefined;
          } else if (item.flag === -1 && itemAction.direction === '' && item.avatarname === undefined && item.unitcode === undefined) {
            item.avatarname = name?.at(name?.length - 1) + ' ' + name?.at(0);
            item.unitcode = unit?.code;
            if (user?.avatar) item.avatar = user.avatar;
            else item.avatar = undefined;
          }
          if (user?.avatar) {
            itemAction.avatar = user.avatar;
          } else {
            itemAction.firstname = auth.users?.find((user) => user._id === itemAction.candidateId)?.firstname;
            itemAction.lastname = auth.users?.find((user) => user._id === itemAction.candidateId)?.lastname;
          }
        } else if (itemAction.actorId === '_hyperspace') {
          const unit = units?.find((unit) => unit._id === itemAction.hostId);
          if (unit != undefined) {
            itemAction.flagProcess = false;
            itemAction.name = unit?.govId;
          }
          else {
            itemAction.flagProcess = false;
            itemAction.name = 'G26.2.02';
          }
        } else if (itemAction.actorId != '' && itemAction.candidateId != 'Cohort-0000001') {
          const user = auth.users?.find((user) => user._id === itemAction.actorId);
          const name = user?.fullname?.split(' ');
          itemAction.flagProcess = false;
          itemAction.avatar = user?.avatar;
          itemAction.name = name?.at(name?.length - 1) + ' ' + name?.at(0);
        } else if (itemAction.candidateType === 'unit') {
          const unit = units?.find((unit) => unit._id === itemAction.candidateId);
          if (unit?.members?.find((userId) => userId === auth.user._id) != undefined) {
            itemAction.flagProcess = true;
          } else {
            itemAction.flagProcess = false;
          }
          itemAction.name = unit?.shortName;
          if (item.flag === -1 && itemAction.direction === '') {
            item.avatarname = unit?.shortName;
            item.unitcode = unit?.code;
          }
        } else {
          if (itemAction.actorId != '' && itemAction.candidateId === 'Cohort-0000001') {
            item.travel = true;
            itemAction.flagProcess = false;
            const user = auth.users?.find((user) => user._id === itemAction.actorId);
            const name = user?.fullname?.split(' ');
            const unit = units?.find((unit) => unit._id === user?.conscriptions?.at(0).unitId);
            itemAction.name = name?.at(name?.length - 1) + ' ' + name?.at(0);
            if (item.flag === -1 && itemAction.direction === '') {
              item.avatarname = name?.at(name?.length - 1) + ' ' + name?.at(0);
            }
            if (user?.avatar) {
              if (item.flag === -1 && itemAction.direction === '') item.avatar = user.avatar;
              itemAction.avatar = user.avatar;
            } else {
              itemAction.firstname = auth.users?.find((user) => user._id === itemAction.candidateId)?.firstname;
              itemAction.lastname = auth.users?.find((user) => user._id === itemAction.candidateId)?.lastname;
            }
          } else if (itemAction?.candidateId === '_hyperspace' && itemAction?.tag === '_pawn') {
            itemAction.firstname = 'L';
            itemAction.lastname = 'T';
            const unit = units?.find((unit) => unit._id === itemAction.hostId);
            if (unit != undefined) {
              itemAction.flagProcess = false;
              itemAction.name = unit?.govId;
            }
            else {
              itemAction.flagProcess = false;
              itemAction.name = 'G26.2.02';
            }
          } else {
            if (item.travel) item.travel = true;
            else if (
              itemAction.candidateId === 'Cohort-0000001' &&
              itemAction.actorId === '' &&
              cohorts?.find(
                (cohort) => cohort._id === 'Cohort-0000001' && cohort.members?.find((userId) => userId === auth.user._id) != undefined
              ) != undefined
            )
              item.pending = true;
            else item.travel = false;
            const cohort = cohorts?.find((cohort) => cohort._id === itemAction.candidateId);
            itemAction.name = cohort?.name;
            itemAction.firstname = cohort?.name;
            itemAction.lastname = '';
            if (item.flag === -1 && itemAction.direction === '') {
              item.avatarname = cohort?.name;
              item.unitcode = cohort?.code;
            }
            if (
              itemAction.actorId === '' &&
              cohorts?.find(
                (cohort) => cohort._id === itemAction.candidateId && cohort.members?.find((userId) => userId === auth.user._id) != undefined
              ) != undefined
            )
              itemAction.flagProcess = true;
            else itemAction.flagProcess = false;
          }
        }

        let bUserInclude = checkUserInclude(dataSpec, auth.user._id, units, cohorts, 'lumen');
        let bConclusion = false;
        if (dataSpec?.bossId == auth.user._id) {
          bConclusion = true;
        }

        if (dataSpec?.progresses?.at(index).step < 2) {
          bConclusion = true;
        }

        let direction = getDirection(dataSpec?.progresses?.at(index));
        if (direction == 'reject' || direction == 'cancel' || direction == 'reset' || direction == 'sign') {
          bConclusion = true;
        }
        if (bUserInclude) {
          bConclusion = true;
        }
        if (itemAction?.tag === '_boss') {
          bConclusion = true;
        }

        itemAction.bConclusion = bConclusion;

        if (item.te) {
          setAppendix(dataSpec?.appendix);
          setIncantation(item.te);
        }

        if (
          item.status === 'pending' &&
          dataSpec?.pendingForCohorts?.length > 0 &&
          !(
            dataSpec.pendingForCohorts?.find(
              (cohortId) =>
                cohorts?.find(
                  (cohort) => cohort._id === cohortId && cohort.members?.find((userId) => userId === auth.user._id) != undefined
                ) != undefined
            ) != undefined
          )
        ) {
          item.travel = undefined;
        }

        if (
          item.name === 'VP Tiếp nhận' &&
          item.status === 'pending' &&
          dataSpec?.pendingForCohorts?.length === 1 &&
          dataSpec.pendingForCohorts?.find(
            (cohortId) =>
              cohorts?.find(
                (cohort) =>
                  cohort._id === cohortId &&
                  cohortId === 'Cohort-0000004' &&
                  cohort.members?.find((userId) => userId === auth.user._id) != undefined
              ) != undefined
          ) != undefined
        ) {
          item.travel = true;
        }

        if (item.name === 'Xét duyệt TC') {
          setIsHaveXetDuyetTC(true);
        }

        if (index === array?.length - 1 && item?.step < 3) {
          setIsHaveXetDuyetTC(false);
        }
        if (
          item.name === 'LĐ.VP duyệt' &&
          item.status === 'pending' &&
          dataSpec?.pendingForCohorts?.length === 1 &&
          dataSpec.pendingForCohorts?.find(
            (cohortId) =>
              cohorts?.find(
                (cohort) => cohort._id === cohortId && cohort.members?.find((userId) => userId === auth.user._id) != undefined
              ) != undefined
          ) != undefined
        ) {
          item.travel = undefined;
        }

        if (item.name === 'Ban GĐ ký' && item.status === 'pending') {
          setPlaceholderText('Phản hồi lý do (trả hồ sơ), hoặc bút phê (ký duyệt)');
        }

        if (
          item.name === 'Ban GĐ ký' &&
          item.status === 'pending' &&
          dataSpec?.pendingForUsers?.length > 0 &&
          dataSpec.pendingForUsers?.find(
            (userId) => auth.users?.find((user) => user._id === userId && (userId === auth.user._id) != undefined) != undefined
          ) != undefined
        ) {
          item.BGD = true;
        }

        if (
          item.name === 'LĐ.VP duyệt' &&
          item.status === 'done' &&
          array[index + 1]?.name === 'Ban GĐ ký' &&
          array[index + 1]?.status === 'pending' &&
          array[index + 1]?.refFileIds?.length > 0
        ) {
          item.refFileIds = array[index + 1]?.refFileIds;
          item.signFileIds = array[index + 1]?.signFileIds;
          item.supFileIds = array[index + 1]?.supFileIds;
        }

        if (
          item.name === 'Ban GĐ ký' &&
          item.status === 'pending' &&
          dataSpec?.pendingForUsers?.length === 1 &&
          dataSpec.pendingForUsers?.find((userId) => userId === auth.user._id) != undefined &&
          dataSpec.pendingForUsers?.at(0) != '129d2929ecae8ee9eca0d6a477_0003' &&
          dataSpec.pendingForUsers?.at(0) != '129d2929ecae8ee9eca0d6a477_0004' &&
          itemAction?.tag === '_per-pro' &&
          itemAction?.prerogatives?.find((id) => id === 'digital-sign') != undefined
        ) {
          item.Kythua = true;
        }
        
        if (
          item.name === 'Ban GĐ ký' &&
          item.status === 'pending' &&
          item?.actions?.find(action=> action?.actorId === '' && action?.tag != '_boss' && 
          ((action?.candidateType ==='user' && action?.candidateId === auth.user._id) || 
            (action?.candidateType ==='unit' && units?.find(unit => unit?._id === action?.candidateId && unit?.members?.find(id => id === auth.user._id) != undefined) != undefined)  )
            ) != undefined
        ) {
          item.NotBoss = true;
        }

        if (
          item.name === 'Nhận văn bản' &&
          item.status === 'pending' &&
          dataSpec?.pendingForUsers?.length > 0 &&
          dataSpec.pendingForUsers?.find(
            (userId) => auth.users?.find((user) => user._id === userId && (userId === auth.user._id) != undefined) != undefined
          ) != undefined
        ) {
          item.NVB = true;
        }

        if (
          item.name === 'Ban GĐ ký' &&
          item.status === 'pending' &&
          dataSpec?.pendingForUsers?.length > 0 &&
          dataSpec.pendingForUsers?.find(
            (userId) => auth.users?.find((user) => user._id === userId && (userId === auth.user._id) != undefined) != undefined
          ) != undefined &&
          itemAction?.prerogatives?.at(0) === 'digital-sign'
        ) {
          item.digitalSign = true;
        }

        if (item.name === 'Xét duyệt TC' && item.status === 'pending') {
          item.xetduyettc = true;
        }
        if (indexAction === 0) {
          item.actionTime = parseFloat(itemAction.atTime);
        } else if (indexAction < arrayAction?.length - 1) {
          if (itemAction?.atTime > arrayAction[indexAction + 1]?.atTime) item.actionTime = parseFloat(itemAction.atTime);
          else item.actionTime = parseFloat(arrayAction[indexAction + 1]?.atTime);
        }
        return itemAction;
      });
      if (item.actions?.length > 0) {
        Promise.all(item.actions).then((newData) => (item.actions = newData));
      }

      if (
        item.name === 'Nộp hồ sơ' &&
        item.status === 'pending' &&
        dataSpec?.pendingForUsers?.length > 0 &&
        dataSpec.pendingForUsers?.find((userId) => userId === auth.user._id) != undefined
      ) {
        setNHS(true);
      }

      if (item?.step === 0 && item.status === 'pending') {
        if (isEdit.index === -1) {
          isEdit.isShow = true;
          isEdit.index = index;
          isEdit.isTrue = true;
          setIsEdit({ ...isEdit });
        }
      }

      if (item.xetduyettc) {
        setIsXetDuyetTC(false);
      }
      if (item.BGD) {
        setBGD(false);
      }

      if (item.Kythua) {
        setIsKyThua(true);
      }
      if (item.NVB || item.NotBoss) {
        setNVB(false);
      }
      if (item.travel != undefined && (item.isSequential === undefined)) {
        setTextWait('Xin chờ các thành viên phụ xử lý trước');
        setIsShowWait(!item.travel);
        setIsTravel(item.travel);
        setIsPending(item.travel);
      }
      if (item.pending != undefined) {
        setIsPending(true);
        setIsShowWait(false);
      }

      if (
        item.digitalSign &&
        auth.user?.simPKI != '' &&
        auth.user?.fullname === dataSpec?.appendix?.signatory
      ) {
        setIsShield(true);
      }

      //  if (
      //   item.digitalSign &&
      //   auth.user?.simPKI != '' &&
      //   !(auth.users?.find((user) => user?.fullname === dataSpec?.appendix?.signatory && user?.usbPKI === 'available') != undefined)
      // ) {
      //   setIsShield(true);
      // }

      const time = new Date(item.actionTime);

      item.actionTimeToString = time.toLocaleString('es-CL', { day: '2-digit', month: '2-digit', year: '2-digit' });
      if (!isShowTL0 && item?.supFileIds?.length > 0) {
        setIsShowTL0(true);
      }
      let totalFile = 0;
      if (item?.refFileIds?.length > 0) {
        totalFile += item?.refFileIds?.length;
        let refname = [];
        let refShield = [];
        for (let i = 0; i < item.refFileIds.length; i++) {
          const file = mapFileNames[item.refFileIds.at(i)];
          refname.push(file?.name);
          refShield.push(file?.notarizations);
        }

        item.refFileName = refname;
        item.refFileShield = refShield;
      }
      if (item?.signFileIds?.length > 0) {
        totalFile += item?.signFileIds?.length;
        let signname = [];
        let signShield = [];
        for (let i = 0; i < item.signFileIds.length; i++) {
          const file = mapFileNames[item.signFileIds.at(i)];
          signname.push(file?.name);
          signShield.push(file?.notarizations);
        }

        item.signFileName = signname;
        item.signFileShield = signShield;
      }
      if (item?.supFileIds?.length > 0) {
        totalFile += item?.supFileIds?.length;
        let supname = [];
        for (let i = 0; i < item.supFileIds.length; i++) {
          const file = mapFileNames[item.supFileIds.at(i)];
          supname.push(file?.name);
        }

        item.supFileName = supname;
      }
      if (totalFile > 4 && !isShowCollapse) {
        setIsShowCollapse(true);
      }

      if (item.status === 'done' && ((index < array.length - 1 && array[index + 1].status !== 'done') || index === array.length - 1)) {
        if (isEdit.action === null) {
          isEdit.action = item;
          setIsEdit({ ...isEdit });
          setLeftList(item);
          setIndexChoose(index);
        }
      }

      if (item.status === 'done' && ((index === array.length - 2 && array[index + 1].status !== 'done') || index === array.length - 1)) {
        setTempSpec(item);
      }
      if (index === array.length - 1) {
        Promise.all(item.actions).then((action) => {
          rightList.process = false;
          rightList.data = action;
          setRightList({ ...rightList });
        });
      }
      return item;
    });

    if (data?.length > 0) {
      Promise.all(data).then((newData) => setDataTimeLineProcess(newData));
    }
  }, [dataTimeLine, auth, auth.users, units, dataSpec, mapFileNames, cohorts]);

  useEffect(() => {
    if (rightList.data === null) return;
    if (rightList.process) return;

    rightList.process = true;
    rightList.data = rightList.data
      ?.sort((itemA, itemB) => {
        if (itemA?.isSequential) {
          return !itemA?.isShow && itemB?.isShow ? 1 : -1;
        }
        else return -1;
      })
      ?.sort((itemA, itemB) => {
        if (dataSpec?.status === 'closed') return -1;
        else
          return (itemA?.candidateType === 'user' && itemA?.candidateId === auth.user._id && itemA?.actorId === '') ||
            (itemA?.candidateType === 'cohort' &&
              cohorts?.find(
                (cohort) => cohort?._id === itemA?.candidateId && cohort?.members?.find((id) => id === auth.user._id) != undefined
              ) != undefined && itemA?.actorId === '') ||
            (itemA?.candidateType === 'unit' &&
              units?.find((unit) => unit?._id === itemA?.candidateId && unit?.members?.find((id) => id === auth.user._id) != undefined) !=
                undefined && itemA?.actorId === '')
            ? 1
            : -1;
      })
      ?.map((item, index) => {
        if (dataSpec?.witnessIds?.length > 0) {
          if (item?.tag == '_boss') item.tagname = 'KD';
          else if (item?.tag == '_witness') item.tagname = 'XĐB';
          else if (item?.tag == '_per-pro') item.tagname = 'KTL';
          else item.tagname = undefined;
        }
        if (item?.tag == '_VT') item.tagname = 'VT';
        if (item?.tag == '_pawn' && item?.hostId != '') {
          const unit = units?.find((unit) => unit?._id === item?.hostId);
          if (unit != undefined)
            item.tagname = unit?.code;
          else {
            const cohort = cohorts?.find(cohort => cohort?._id === item?.hostId);
            item.tagname = cohort?.code;
          }
        }
        return item;
      });
    setRightList({ ...rightList });
  }, [auth, auth.user, rightList, units, cohorts]);

  useEffect(() => {
    if (dataTimeLineProcess === null || leftList === null || rightList === null || !rightList.process || !selectedIndexView.flag) return;
    if (!isShowProcess) setIsShowProcess(true);
  }, [dataTimeLineProcess, leftList, rightList, cohorts, auth, auth.user, auth.users, selectedIndexView.flag]);

  const downloadFile = async (id, filename) => {
    const res = await dlFile({ id });

    if (window.ReactNativeWebView) {
      const file = {
        id: id,
        name: filename
      }
      if (!canOpen(filename)) {
        window?.ReactNativeWebView?.postMessage(`DownloadFile:${JSON.stringify(file)}`);
        return;
      }
      window?.ReactNativeWebView?.postMessage(`OpenFile:${JSON.stringify(file)}`);
    }
    else if (Platform.OS === 'web') {
      const href = URL.createObjectURL(res);
      var newHref = ''
      if (filename?.endsWith('.pdf')) {
        newHref = '/filepreview.html?fileid=' + id;

      }

      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = newHref === '' ? href: newHref;
      link.target = '_blank';

      if (!canOpen(filename)) {
        link.setAttribute('download', filename);
      }

       

      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 50);
    }
  };

  const NAME_BGD = ['Chọn đơn vị', 'Ban Giám Đốc'];
  const ID_BGD = ['', 'DPM-0013'];

  const ID_BCN_O = ['ADM-0001', 'MOD-0001', 'DPM-0011', 'DPM-0014', 'DPM-0075', 'DPM-0076', 'DPM-0077', 'DPM-0013'];
  let ID_BCN = ['', '_all_local'];
  let NAME_BCN = ['Chọn đơn vị', 'Tất cả'];

  const NAME_DOAN = ['Chọn đơn vị', 'Ban Cán sự Đoàn', 'VP Công đoàn'];
  const ID_DOAN = ['', 'DPM-0011', 'DPM-0014'];

  const ID_DVTV = ['', '_all_dvtv', 'DPM-0021', 'DPM-0015', 'DPM-0020', 'DPM-0016', 'DPM-0017', 'DPM-0019', 'DPM-0018', 'DPM-0023', 'DPM-0022'];
  const NAME_DVTV = [
    'Chọn đơn vị',
    'Tất cả',
    'Trường ĐH An Giang',
    'Trường ĐH Bách Khoa',
    'Trường ĐH CNTT',
    'Trường ĐH KHTN',
    'Trường ĐH KHXH&NV',
    'Trường ĐH Kinh tế-luật',
    'Trường ĐH Quốc tế',
    'Trường ĐH KH Sức Khoẻ',
    'Viện MT và Tài nguyên'
  ];

  let NAME_DVTT = ['Chọn đơn vị', 'Tất cả'];
  let ID_DVTT = ['', '_all_tether'];

  let NAME_NGOAI = ['Chọn đơn vị'];
  let ID_NGOAI = [''];

  const NAME_OTHER = ['Chọn đơn vị'];

  const [FormState, setFormState] = React.useState({
    CHOOSE: [['Chọn đơn vị'], NAME_BGD, NAME_BCN, NAME_DOAN, NAME_DVTV, NAME_DVTT, NAME_NGOAI, NAME_OTHER],
    CHOOSEID: [[], ID_BGD, ID_BCN, ID_DOAN, ID_DVTV, ID_DVTT, ID_NGOAI, ['']],
    isProcess: false,
  });

  useEffect(() => {
    if (FormState.isProcess) return;
    if (units === null) return;
    if (Appendix === null) return;

    AppendixProcess.documentNumber = Appendix?.documentNumber;
    AppendixProcess.signatory = Appendix?.signatory;

    setAppendixProcess({ ...AppendixProcess });
    setIsShowChoose(false);
    const BCN = units?.filter((item) => item?.turf === 'local' && ID_BCN_O?.find((id) => id === item?._id) === undefined);
    for (let i = 0; i < BCN?.length; i++) {
      FormState.CHOOSE[2].push(BCN[i]?.name);
      FormState.CHOOSEID[2].push(BCN[i]?._id);
    }
    const NGOAI = units?.filter((item) => item?.turf === 'external');
    for (let i = 0; i < NGOAI?.length; i++) {
      FormState.CHOOSE[6].push(NGOAI[i]?.name);
      FormState.CHOOSEID[6].push(NGOAI[i]?._id);
    }
    const DVTT = units?.filter((item) => item?.turf === 'tether' && ID_DVTV.find((id) => id === item?._id) === undefined);
    for (let i = 0; i < DVTT?.length; i++) {
      FormState.CHOOSE[5].push(DVTT[i]?.name);
      FormState.CHOOSEID[5].push(DVTT[i]?._id);
    }
    FormState.isProcess = true;

    setFormState({ ...FormState });
  }, [units, FormState, Appendix]);

  const addDVNState = () => {
    DVNState.push({ index: 0, indexChoose: 0 });
    setDVNState([...DVNState]);
  };

  const removeDVState = (index) => {
    DVNState.splice(index, 1);
    setDVNState([...DVNState]);
  };

  const updateAppendix = ({ documentNumber, signatory }) => {
    if (documentNumber != null) AppendixProcess.documentNumber = documentNumber;
    else if (signatory != null) AppendixProcess.signatory = signatory;
    setAppendixProcess({ ...AppendixProcess });
  };

  const ID_TYPE = ['Chọn', 'BGĐ', 'BCN', 'Đoàn', 'ĐVTV', 'ĐVTT', 'Ngoài', 'Khác'];

  const [DVNState, setDVNState] = useState([]);

  // const displayValueDVCHOOSE = DV[selectedIndexDVCHOOSE.row];
  const ID_OPS = ['', '_bgd', '_bcn', '_doan', '_dvtv', 'tether', 'external', 'other'];

  const [isIncatationProcess, setIsIncatationProcess] = useState(false);

  useEffect(() => {
    if (Incantation?.length <= 0) return;
    if (isIncatationProcess) return;
    if (!FormState.isProcess) return;

    Incantation?.map((item) => {
      const index = ID_OPS?.indexOf(item?.turf);
      const DVtemp = FormState.CHOOSEID[index];
      let indexChoose = DVtemp?.indexOf(item?.unit);
      if (index === 7) {
        indexChoose = item?.unit.slice(1);
      }

      DVNState.push({ index: index, indexChoose: indexChoose });
    });
    setDVNState([...DVNState]);
    setIsIncatationProcess(true);
  }, [Incantation, ID_OPS, FormState, FormState.CHOOSEID, FormState.CHOOSE, isIncatationProcess]);
  useEffect(() => {
    if (FormState.isProcess && DVNState?.length > 0) {
      setIsShowChoose(true);
    }
  }, [FormState, FormState.CHOOSE, FormState.CHOOSEID, DVNState]);

  const AlertHandlerTL = () => {
    //function to make simple alert
    alert('Chưa upload tờ trình');
  };
  const AlertHandlerVB = () => {
    //function to make simple alert
    alert('Chưa upload văn bản');
  };

  const AlertHandlerKhac = () => {
    //function to make simple alert
    alert('Chưa nhập nơi nhận');
  };

  const AlertHandlerHuongXL = () => {
    //function to make simple alert
    alert('Chưa chọn hướng xử lý');
  };

  const AlertHandlerDVN = () => {
    //function to make simple alert
    alert('Chưa chọn đơn vị nhận');
  };

  const AlertHandlerSoVB = () => {
    //function to make simple alert
    alert('Số văn bản không hợp lệ');
  };

  const AlertHandlerNguoiKy = () => {
    //function to make simple alert
    alert('Người ký không hợp lệ');
  };

  const AlertHandlerKyThua = (message) => {
    //function to make simple alert
    alert(message);
  };

  const OnClick = async (direction: string) => {
    let actionIndex = 0;
    if (rightList.data.find((action) => action.isSequential === true && action.flagProcess === true && action.isShow === true) != undefined)
      actionIndex = rightList.data.find((action) => action.flagProcess === true && action.isShow === true)?.actionIndex;
    else
      actionIndex = rightList.data.find((action) => action.flagProcess === true)?.actionIndex;
    let res;
    if (isEdit.isTrue && isEdit.action?.refFileIds?.length <= 0 && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerTL();
      return;
    }
    if (isEdit.isTrue && isEdit.action?.signFileIds?.length <= 0 && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerVB();
      return;
    }

    if (isBGD && AppendixProcess.documentNumber === '' && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerSoVB();
      return;
    }

    if (isBGD && AppendixProcess.signatory === '' && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerNguoiKy();
      return;
    }
    if (isTravel && isXetDuyetTC && selectedIndex.row === 0 && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
      AlertHandlerHuongXL();
      return;
    }
    if (tempSpec === null) return;

    let incatation = [];
    let destination = [];
    for (let i = 0; i < DVNState.length; i++) {
      if (isIncatationProcess && (DVNState?.at(i)?.index === 0 || DVNState?.at(i)?.indexChoose === 0) && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
        AlertHandlerDVN();
        return;
      }
      if (isIncatationProcess && DVNState?.at(i)?.index === 7 && DVNState?.at(i)?.indexChoose === '' && (direction != 'cancel' && direction != 'reject' && direction != 'reset')) {
        AlertHandlerKhac();
        return;
      }
      incatation.push({
        turf: ID_OPS[DVNState?.at(i)?.index],
        unit:
          DVNState?.at(i)?.index === 7
            ? '#' + DVNState?.at(i)?.indexChoose
            : FormState.CHOOSEID[DVNState?.at(i)?.index][DVNState?.at(i)?.indexChoose],
      });
      if (DVNState?.at(i)?.index != 7 && FormState.CHOOSE[DVNState?.at(i)?.index][DVNState?.at(i)?.indexChoose] == 'Tất cả') {
        for (let j = 2; j < FormState.CHOOSE[DVNState?.at(i)?.index].length; j++) {
          if (direction === 'sign' && destination?.find(des => des === FormState.CHOOSEID[DVNState?.at(i)?.index][j]) != undefined) continue;
          destination.push(FormState.CHOOSEID[DVNState?.at(i)?.index][j]);
        }
      } else {
        if (direction === 'sign' && destination?.find(des => des === (DVNState?.at(i)?.index === 7
        ? '#' + DVNState?.at(i)?.indexChoose
        : FormState.CHOOSEID[DVNState?.at(i)?.index][DVNState?.at(i)?.indexChoose])) != undefined) continue;
        destination.push(
          DVNState?.at(i)?.index === 7
            ? '#' + DVNState?.at(i)?.indexChoose
            : FormState.CHOOSEID[DVNState?.at(i)?.index][DVNState?.at(i)?.indexChoose]
        );
      }
    }
    setWaiting(true);
    if (direction === 'sign' && isShield) {
      isSignModal.isShowSignModal = true;
      setIsSignModal({ ...isSignModal });
      if (auth.user?.simPKI != '') {
        signState.phoneNumber = '+' + auth.user?.simPKI;
      }
      let i = signState.soTL.index;
      if (signState.soTL.length === 0) {
        signState.soTL.length = tempSpec?.refFileIds?.length + tempSpec?.signFileIds?.length;
        signState.soTL.index = 0;
        i = 0;
      }
      for (i; i < tempSpec?.refFileIds?.length + tempSpec?.signFileIds?.length; i++) {
        if (i < tempSpec?.refFileIds?.length) {
          let mkt = await digestMessage(tempSpec?.refFileIds?.at(i));
          signState.maKtra = mkt;
          signState.fileName = tempSpec?.refFileName?.at(i);
          signState.soTL.index = i;
          setSignState({ ...signState });
          let result = await getSign('Lumen', conclusion, tempSpec?.refFileIds?.at(i), specId.data);
          if (result.status === 'ok') {
            digitalSignFile.refFileIds.push(result.result.fileId);
          } else {
            setDigitalSignFile({ ...digitalSignFile });
            isSignModal.isShowSignFail = true;
            setIsSignModal({ ...isSignModal });
            setTimeout(() => setWaiting(false), 200);
            return;
          }
        } else {
          let mkt = await digestMessage(tempSpec?.signFileIds?.at(i - tempSpec?.refFileIds?.length));
          signState.maKtra = mkt;
          signState.fileName = tempSpec?.signFileName?.at(i - tempSpec?.refFileIds?.length);
          signState.soTL.index = i;
          setSignState({ ...signState });
          let result = await getSign('Lumen', null, tempSpec?.signFileIds?.at(i - tempSpec?.refFileIds?.length), specId.data);
          if (result.status === 'ok') {
            digitalSignFile.signFileIds.push(result.result.fileId);
          } else {
            setDigitalSignFile({ ...digitalSignFile });
            isSignModal.isShowSignFail = true;
            setIsSignModal({ ...isSignModal });
            setTimeout(() => setWaiting(false), 200);
            return;
          }
        }
      }
      setDigitalSignFile({ ...digitalSignFile });
    }
    if (direction === 'sign') {
      if (isKyThua)
        res = await updateSigningProcess(
          {
            actionIndex: actionIndex,
            direction: 'sign',
            conclusion: conclusion,
            incantation: '',
            designation: null,
            modification: null,
            destination: destination,
            next: {},
            nextStep: null,
            refFileIds: isShield ? digitalSignFile.refFileIds : tempSpec.refFileIds,
            signFileIds: isEdit.action.signFileIds,
            supFileIds: tempSpec.supFileIds,
          },
          specName,
          specId.data
        );
      else
        res = await updateSigningProcess(
          {
            actionIndex: actionIndex,
            direction: 'sign',
            conclusion: conclusion,
            incantation: '',
            designation: null,
            modification: null,
            destination: destination,
            next: {},
            nextStep: null,
            refFileIds: isShield ? digitalSignFile.refFileIds : tempSpec.refFileIds,
            signFileIds: isShield ? digitalSignFile.signFileIds : tempSpec.signFileIds,
            supFileIds: tempSpec.supFileIds,
          },
          specName,
          specId.data
        );
    } else if (direction === 'confirm') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'confirm',
          conclusion: conclusion,
          designation: null,
          destination: null,
          incantation: '',
          modification: null,
          next: {},
          nextStep: nextStep,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'approve') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'approve',
          conclusion: conclusion,
          destination: null,
          designation: null,
          incantation: JSON.stringify(incatation),
          modification: AppendixProcess,
          next: {},
          nextStep: nextStep,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'submit') {
      if (isEdit.isTrue)
        res = await updateSigningProcess(
          {
            actionIndex: actionIndex,
            direction: 'submit',
            conclusion: conclusion,
            designation: null,
            destination: null,
            incantation: JSON.stringify(incatation),
            modification: AppendixProcess,
            next: {},
            nextStep: null,
            refFileIds: isEdit.action.refFileIds,
            signFileIds: isEdit.action.signFileIds,
            supFileIds: isEdit.action.supFileIds,
          },
          specName,
          specId.data
        );
      else
        res = await updateSigningProcess(
          {
            actionIndex: actionIndex,
            direction: 'submit',
            conclusion: conclusion,
            destination: null,
            designation: null,
            incantation: JSON.stringify(incatation),
            modification: AppendixProcess,
            next: {},
            nextStep: null,
            refFileIds: tempSpec.refFileIds,
            signFileIds: tempSpec.signFileIds,
            supFileIds: tempSpec.supFileIds,
          },
          specName,
          specId.data
        );
    } else if (direction === 'reject') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'reject',
          conclusion: conclusion,
          designation: null,
          destination: null,
          incantation: JSON.stringify(incatation),
          modification: AppendixProcess,
          next: {},
          nextStep: null,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'reset') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'reset',
          conclusion: conclusion,
          designation: null,
          destination: null,
          incantation: JSON.stringify(incatation),
          modification: AppendixProcess,
          next: {},
          nextStep: null,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    } else if (direction === 'cancel') {
      res = await updateSigningProcess(
        {
          actionIndex: actionIndex,
          direction: 'cancel',
          conclusion: conclusion,
          designation: null,
          destination: null,
          incantation: JSON.stringify(incatation),
          modification: AppendixProcess,
          next: {},
          nextStep: null,
          refFileIds: tempSpec.refFileIds,
          signFileIds: tempSpec.signFileIds,
          supFileIds: tempSpec.supFileIds,
        },
        specName,
        specId.data
      );
    }

    if (res?.status === 'ok' && !isBGD) navigate('/lumens');
    else if (res?.status === 'error') {
      AlertHandlerKyThua(res?.message);
      setTimeout(() => setWaiting(false), 200);
      return;
    }

    setIsEdit({ isTrue: false, isShow: false, action: null, index: -1, isClone: false });
    setConclusion('');
    setTimeout(() => setWaiting(false), 200);
    rootSpec.refetch();
  };

  var [indexChoose, setIndexChoose] = React.useState(0);
  var [isShowChoose, setIsShowChoose] = React.useState(false);

  const removeFile = (type: string, index: number) => {
    if (!isEdit.isClone) {
      isEdit.isClone = true;
      isEdit.action = JSON.parse(JSON.stringify(isEdit.action));
    }
    if (type === 'ref') {
      isEdit.action.refFileName.splice(index, 1);
      isEdit.action.refFileIds.splice(index, 1);
      setIsEdit({ ...isEdit });
    } else if (type === 'sign') {
      isEdit.action.signFileName.splice(index, 1);
      isEdit.action.signFileIds.splice(index, 1);
      setIsEdit({ ...isEdit });
    } else if (type === 'sup') {
      isEdit.action.supFileName.splice(index, 1);
      isEdit.action.supFileIds.splice(index, 1);
      setIsEdit({ ...isEdit });
    }
  };

  const uploadNewFile = async (type: string, isEditd: any) => {
    if (!isEdit.isClone) {
      isEdit.isClone = true;
      isEdit.action = JSON.parse(JSON.stringify(isEdit.action));
    }
    const result = await DocumentPicker.getDocumentAsync({});
    const formData = new FormData();
    formData.append('parentId', '_temp');
    formData.append('attemptFor', specName.search('DLumen') >= 0 ? 'DLumen' : 'Lumen');
    formData.append('filedata', (result as any).file);
    if (isKyThua) formData.append('signature', 'verifying');

    const temp = await uploadFile({ data: formData });
    if (isKyThua && temp?.status === 'error') {
      AlertHandlerKyThua(temp?.message);
      return;
    }
    const fileid = temp?.result?.fileIds?.at(0);
    let filename = '';
    await getNameFile({ id: fileid }).then((name) => {
      filename = name;
    });
    if (type === 'ref') {
      isEdit.action.refFileName = [filename];
      isEdit.action.refFileIds = [fileid];
      setIsEdit({ ...isEdit });
    } else if (type === 'sign') {
      if (isEdit.action.signFileName?.length > 0) {
        isEdit.action.signFileName?.push(filename);
      } else {
        isEdit.action.signFileName = [filename];
      }
      isEdit.action.signFileIds?.push(fileid);
      setIsEdit({ ...isEdit });
    } else if (type === 'sup') {
      if (isEdit.action.supFileName?.length > 0) {
        isEdit.action.supFileName?.push(filename);
      } else {
        isEdit.action.supFileName = [filename];
      }
      isEdit.action.supFileIds?.push(fileid);
      setIsEdit({ ...isEdit });
    }
  };

  useEffect(() => {
    if (dataSpec === null) return;
    let tempflag = false;
    if (dataSpec?.pendingForUsers?.length > 0 && dataSpec.pendingForUsers?.find((id) => id === auth.user._id) != undefined) tempflag = true;
    if (
      dataSpec?.pendingForUnits?.length > 0 &&
      dataSpec.pendingForUnits?.find(
        (unitId) =>
          units?.find((unit) => unit._id === unitId && unit.members?.find((userId) => userId === auth.user._id) != undefined) != undefined
      ) != undefined
    )
      tempflag = true;
    if (
      dataSpec?.pendingForCohorts?.length > 0 &&
      dataSpec.pendingForCohorts?.find(
        (cohortId) =>
          cohorts?.find((cohort) => cohort._id === cohortId && cohort.members?.find((userId) => userId === auth.user._id) != undefined) !=
          undefined
      ) != undefined
    )
      tempflag = true;
    setIsShowRequest(tempflag);
  }, [dataSpec, units, cohorts, auth.user]);

  const renderStatus = ({ item, index }) => {
    if (
      leftList?.actions?.length > 6 &&
      !isShowMore &&
      index > 0 &&
      ((index < leftList?.actions?.length - 1 && !isPending) || (index < leftList?.actions?.length - 2 && isPending))
    )
      return null;
    else if (leftList?.actions?.length > 6 && !isShowMore && index === leftList?.actions?.length - 1 && !isPending)
      return (
        <ListItem
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 8,
          }}
          key={index}
          onPress={() => setIsShowMore(true)}
        >
          <MoreIcon></MoreIcon>
          <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
            Hiển thị tiếp {leftList?.actions?.length - 1} xử lý khác...
          </Text>
        </ListItem>
      );
    else if (leftList?.actions?.length > 6 && !isShowMore && index === leftList?.actions?.length - 2 && isPending)
      return (
        <ListItem
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 8,
          }}
          key={index}
          onPress={() => setIsShowMore(true)}
        >
          <MoreIcon></MoreIcon>
          <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
            Hiển thị tiếp {leftList?.actions?.length - 2} xử lý khác...
          </Text>
        </ListItem>
      );
    return (
      <View style={styles.processView} key={index}>
        <View style={styles.columnmaxWidth}>
          <View style={styles.rowmaxWidthPb5}>
            <AvatarImage avatar={item.avatar} name={item.name} firstname={item.firstname} lastname={item.lastname}></AvatarImage>
            <View style={styles.columnPl5}>
              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <Text style={{ fontSize: 15 }} category="s1">
                  {item.name} <Text style={{ fontSize: 12, color: '#787777' }}> {item.timeprocess}</Text>
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 15, color: item.processcolor, marginTop: 2 }}>
                  {item.process}
                  {item?.tagname && (
                    <View
                      style={{
                        backgroundColor: '#9ca59d',
                        paddingHorizontal: 5,
                        borderRadius: 5,
                        minWidth: 37,
                        alignItems: 'center',
                        marginTop: 2,
                        marginLeft: 7,
                        paddingVertical: 2,
                      }}
                    >
                      <Text style={{ color: '#FFFFFF', fontSize: 13 }}>{item.tagname}</Text>
                    </View>
                  )}
                </Text>
              </View>
            </View>
          </View>
          {item.bConclusion === true && (
            <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              <Text style={{ lineHeight: 25 }}>{item.conclusion != '' ? item.conclusion : '(Không có diễn giải chi tiết)'}</Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  const [isShowMore, setIsShowMore] = React.useState(false);
  const MoreIcon = (props) => <Icon2 {...props} style={styles.icon} name="unfold-more-horizontal" color="#787777" />;

  const renderStatusRight = ({ item, index }) => {
    if (item?.isSequential && !item?.isShow) return;
    if (item?.isSequential && item?.isShow && item?.actionIndex === 0 && ((item?.flagProcess === true && item?.actorId === '') || (!(isPending && isShowRequest) && item?.actorId === ''))) {
      let nextData;
      let nextListData;
      if (rightList.data?.length > 3) {
        nextData = rightList.data?.find(action => action?.actionIndex === 1);
        nextListData = rightList.data?.filter(action => action?.actionIndex != 0 && action?.actionIndex != 1);
      } else if (rightList.data?.length === 3) {
        nextData = rightList.data?.find(action => action?.actionIndex === 1);
        nextListData = rightList.data?.find(action => action?.actionIndex === 2);
      }
      else if (rightList.data?.length > 1) {
        nextData = rightList.data?.find(action => action?.actionIndex === 1);
      }
      if (nextData != undefined && nextListData != undefined && nextData?.candidateType === 'user') {
        if (nextData?.hostId != '') {
          const unit = units?.find(unit => unit?._id === nextData?.hostId)
          if (unit != undefined)
            nextData.unitName = unit?.shortName;
          else {
            const cohort = cohorts?.find(cohort => cohort?._id === item?.hostId);
            nextData.unitName = cohort?.code;
          }
        } else {
          const user = auth.users?.find(user => user?._id === nextData?.candidateId);
          const unit = user?.conscriptions?.at(0);
          nextData.unitName = unit?.unitName;
        }
      }
      if (nextListData != undefined && nextListData?.length === undefined && nextListData?.candidateType === 'user') {
        if (nextListData?.hostId != '') {
          const unit = units?.find(unit => unit?._id === nextListData?.hostId)
          if (unit != undefined)
            nextListData.unitName = unit?.shortName;
          else {
            const cohort = cohorts?.find(cohort => cohort?._id === item?.hostId);
            nextListData.unitName = cohort?.code;
          }
        } else {
          const user = auth.users?.find(user => user?._id === nextListData?.candidateId);
          const unit = user?.conscriptions?.at(0);
          nextListData.unitName = unit?.unitName;
        }
      }
      else if (nextListData != undefined && nextListData?.length > 1) {
        nextListData?.sort((itemA, itemB) => {
          return itemA?.actionIndex > itemB?.actionIndex ? 1 : -1;
        })
      }
      return (
        <View style={{width: '100%', flexDirection:'column'}} key={index}>
          {!(isPending && isShowRequest) && (<View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <AvatarImage avatar={item.avatar} name={item.name} firstname={item.firstname} lastname={item.lastname}></AvatarImage>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {item.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      {item.process}
                      {item?.tagname && (
                        <View
                          style={{
                            backgroundColor: '#9ca59d',
                            paddingHorizontal: 5,
                            borderRadius: 5,
                            minWidth: 37,
                            alignItems: 'center',
                            marginTop: 2,
                            marginLeft: 7,
                            paddingVertical: 2,
                          }}
                        >
                          <Text style={{ color: '#FFFFFF', fontSize: 13 }}>{item.tagname}</Text>
                        </View>
                      )}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
                {item.process != 'Chờ xử lý' && (
                  <Text style={{ lineHeight: 25 }}>{item.conclusion != '' ? item.conclusion : '(Không có diễn giải chi tiết)'}</Text>
                )}
              </View>
            </View>
          </View>)}
          {rightList.data?.length > 3 && (
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingLeft: 0, height: 40, width:47, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage name={`+ ${nextListData?.length}`}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialLeft(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialLeft}
                  onBackdropPress={() => {
                    setVisibleIsSequentialLeft(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextListData?.map(
                        (item, index) =>
                          <Text
                            style={{
                              color: '#FFFFFF',
                              marginBottom: index === nextListData?.length - 1 ? 0 : 4,
                              fontSize: 14,
                            }}
                            key={item?.name}
                          >
                            {item?.name}
                          </Text>
                      )}
                    </View>
                  </View>
                </Tooltip>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingHorizontal: 0, height: 40, width:40, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage avatar={nextData?.avatar} name={nextData?.name} firstname={nextData?.firstname} lastname={nextData?.lastname}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialRight(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialRight}
                  onBackdropPress={() => {
                    setVisibleIsSequentialRight(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextData?.unitName === undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 4,
                          fontSize: 14,
                        }}
                      >
                        Thành viên
                      </Text>
                    )}
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {nextData?.name}
                      </Text>
                    {nextData?.unitName != undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                        }}
                      >
                        {nextData?.unitName}
                      </Text>
                    )}
                    </View>
                  </View>
                </Tooltip>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {nextData?.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      Các xử lý tiếp theo
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              </View>
            </View>
          </View>)}
          {rightList.data?.length === 3 && (
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingLeft: 0, height: 40, width:47, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage avatar={nextListData?.avatar} name={`${nextListData?.name}`}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialLeft(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialLeft}
                  onBackdropPress={() => {
                    setVisibleIsSequentialLeft(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextListData?.unitName === undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 4,
                          fontSize: 14,
                        }}
                      >
                        Thành viên
                      </Text>
                    )}
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {nextListData?.name}
                      </Text>
                    {nextListData?.unitName != undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                        }}
                      >
                        {nextListData?.unitName}
                      </Text>
                    )}
                    </View>
                  </View>
                </Tooltip>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingHorizontal: 0, height: 40, width:40, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage avatar={nextData?.avatar} name={nextData?.name} firstname={nextData?.firstname} lastname={nextData?.lastname}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialRight(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialRight}
                  onBackdropPress={() => {
                    setVisibleIsSequentialRight(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextData?.unitName === undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 4,
                          fontSize: 14,
                        }}
                      >
                        Thành viên
                      </Text>
                    )}
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {nextData?.name}
                      </Text>
                    {nextData?.unitName != undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                        }}
                      >
                        {nextData?.unitName}
                      </Text>
                    )}
                    </View>
                  </View>
                </Tooltip>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {nextData?.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      Các xử lý tiếp theo
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              </View>
            </View>
          </View>)}
          {rightList.data?.length === 2 && (
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <AvatarImage avatar={nextData?.avatar} name={nextData?.name} firstname={nextData?.firstname} lastname={nextData?.lastname}></AvatarImage>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {nextData?.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      Xử lý tiếp theo
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              </View>
            </View>
          </View>)}
          {(isPending && isShowRequest) && (<View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <AvatarImage avatar={item.avatar} name={item.name} firstname={item.firstname} lastname={item.lastname}></AvatarImage>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {item.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      {item.process}
                      {item?.tagname && (
                        <View
                          style={{
                            backgroundColor: '#9ca59d',
                            paddingHorizontal: 5,
                            borderRadius: 5,
                            minWidth: 37,
                            alignItems: 'center',
                            marginTop: 2,
                            marginLeft: 7,
                            paddingVertical: 2,
                          }}
                        >
                          <Text style={{ color: '#FFFFFF', fontSize: 13 }}>{item.tagname}</Text>
                        </View>
                      )}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
                {item.process != 'Chờ xử lý' && (
                  <Text style={{ lineHeight: 25 }}>{item.conclusion != '' ? item.conclusion : '(Không có diễn giải chi tiết)'}</Text>
                )}
              </View>
            </View>
          </View>)}
        </View>
      );
    } 
    else if (item?.isSequential && item?.isShow && ((item?.flagProcess === true && item?.actorId === '') || (!(isPending && isShowRequest) && item?.actorId === ''))) {
      let nextData;
      let nextListData;
      if (rightList.data?.length - item?.actionIndex > 3) {
        nextData = rightList.data?.find(action => action?.actionIndex === 1 + item?.actionIndex);
        nextListData = rightList.data?.filter(action => action?.actionIndex > 1 + item?.actionIndex);
      } else if (rightList.data?.length - item?.actionIndex === 3) {
        nextData = rightList.data?.find(action => action?.actionIndex === 1 + item?.actionIndex);
        nextListData = rightList.data?.find(action => action?.actionIndex === 2 + item?.actionIndex);
      }
      else if (rightList.data?.length - item?.actionIndex > 1) {
        nextData = rightList.data?.find(action => action?.actionIndex === 1 + item?.actionIndex);
      }
      if (nextData != undefined && nextListData != undefined && nextData?.candidateType === 'user') {
        if (nextData?.hostId != '') {
          const unit = units?.find(unit => unit?._id === nextData?.hostId)
          if (unit != undefined)
            nextData.unitName = unit?.shortName;
          else {
            const cohort = cohorts?.find(cohort => cohort?._id === item?.hostId);
            nextData.unitName = cohort?.code;
          }
        } else {
          const user = auth.users?.find(user => user?._id === nextData?.candidateId);
          const unit = user?.conscriptions?.at(0);
          nextData.unitName = unit?.unitName;
        }
      }
      if (nextListData != undefined && nextListData?.length === undefined && nextListData?.candidateType === 'user') {
        if (nextListData?.hostId != '') {
          const unit = units?.find(unit => unit?._id === nextListData?.hostId)
          if (unit != undefined)
            nextListData.unitName = unit?.shortName;
          else {
            const cohort = cohorts?.find(cohort => cohort?._id === item?.hostId);
            nextListData.unitName = cohort?.code;
          }
        } else {
          const user = auth.users?.find(user => user?._id === nextListData?.candidateId);
          const unit = user?.conscriptions?.at(0);
          nextListData.unitName = unit?.unitName;
        }
      }
      else if (nextListData != undefined && nextListData?.length > 1) {
        nextListData?.sort((itemA, itemB) => {
          return itemA?.actionIndex > itemB?.actionIndex ? 1 : -1;
        })
      }

      return (
        <View style={{width: '100%', flexDirection:'column'}} key={index}>
          {isPending && isShowRequest && rightList.data?.length - item?.actionIndex > 3 && (
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingLeft: 0, height: 40, width:47, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage name={`+ ${nextListData?.length}`}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialLeft(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialLeft}
                  onBackdropPress={() => {
                    setVisibleIsSequentialLeft(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextListData?.map(
                        (item, index) =>
                          <Text
                            style={{
                              color: '#FFFFFF',
                              marginBottom: index === nextListData?.length - 1 ? 0 : 4,
                              fontSize: 14,
                            }}
                            key={item?.name}
                          >
                            {item?.name}
                          </Text>
                      )}
                    </View>
                  </View>
                </Tooltip>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingHorizontal: 0, height: 40, width:40, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage avatar={nextData?.avatar} name={nextData?.name} firstname={nextData?.firstname} lastname={nextData?.lastname}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialRight(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialRight}
                  onBackdropPress={() => {
                    setVisibleIsSequentialRight(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextData?.unitName === undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 4,
                          fontSize: 14,
                        }}
                      >
                        Thành viên
                      </Text>
                    )}
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {nextData?.name}
                      </Text>
                    {nextData?.unitName != undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                        }}
                      >
                        {nextData?.unitName}
                      </Text>
                    )}
                    </View>
                  </View>
                </Tooltip>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {nextData?.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      Các xử lý tiếp theo
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              </View>
            </View>
          </View>)}
          {isPending && isShowRequest && rightList.data?.length - item?.actionIndex === 3 && (
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingLeft: 0, height: 40, width:47, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage avatar={nextListData?.avatar} name={`${nextListData?.name}`}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialLeft(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialLeft}
                  onBackdropPress={() => {
                    setVisibleIsSequentialLeft(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextListData?.unitName === undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 4,
                          fontSize: 14,
                        }}
                      >
                        Thành viên
                      </Text>
                    )}
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {nextListData?.name}
                      </Text>
                    {nextListData?.unitName != undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                        }}
                      >
                        {nextListData?.unitName}
                      </Text>
                    )}
                    </View>
                  </View>
                </Tooltip>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingHorizontal: 0, height: 40, width:40, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage avatar={nextData?.avatar} name={nextData?.name} firstname={nextData?.firstname} lastname={nextData?.lastname}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialRight(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialRight}
                  onBackdropPress={() => {
                    setVisibleIsSequentialRight(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextData?.unitName === undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 4,
                          fontSize: 14,
                        }}
                      >
                        Thành viên
                      </Text>
                    )}
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {nextData?.name}
                      </Text>
                    {nextData?.unitName != undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                        }}
                      >
                        {nextData?.unitName}
                      </Text>
                    )}
                    </View>
                  </View>
                </Tooltip>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {nextData?.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      Các xử lý tiếp theo
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              </View>
            </View>
          </View>)}
          {isPending && isShowRequest && rightList.data?.length - item?.actionIndex === 2 && (
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <AvatarImage avatar={nextData?.avatar} name={nextData?.name} firstname={nextData?.firstname} lastname={nextData?.lastname}></AvatarImage>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {nextData?.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      Xử lý tiếp theo
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              </View>
            </View>
          </View>)}
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <AvatarImage avatar={item.avatar} name={item.name} firstname={item.firstname} lastname={item.lastname}></AvatarImage>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {item.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      {item.process}
                      {item?.tagname && (
                        <View
                          style={{
                            backgroundColor: '#9ca59d',
                            paddingHorizontal: 5,
                            borderRadius: 5,
                            minWidth: 37,
                            alignItems: 'center',
                            marginTop: 2,
                            marginLeft: 7,
                            paddingVertical: 2,
                          }}
                        >
                          <Text style={{ color: '#FFFFFF', fontSize: 13 }}>{item.tagname}</Text>
                        </View>
                      )}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
                {item.process != 'Chờ xử lý' && (
                  <Text style={{ lineHeight: 25 }}>{item.conclusion != '' ? item.conclusion : '(Không có diễn giải chi tiết)'}</Text>
                )}
              </View>
            </View>
          </View>
          {!(isPending && isShowRequest) && rightList.data?.length - item?.actionIndex > 3 && (
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingLeft: 0, height: 40, width:47, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage name={`+ ${nextListData?.length}`}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialLeft(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialLeft}
                  onBackdropPress={() => {
                    setVisibleIsSequentialLeft(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextListData?.map(
                        (item, index) =>
                          <Text
                            style={{
                              color: '#FFFFFF',
                              marginBottom: index === nextListData?.length - 1 ? 0 : 4,
                              fontSize: 14,
                            }}
                            key={item?.name}
                          >
                            {item?.name}
                          </Text>
                      )}
                    </View>
                  </View>
                </Tooltip>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingHorizontal: 0, height: 40, width:40, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage avatar={nextData?.avatar} name={nextData?.name} firstname={nextData?.firstname} lastname={nextData?.lastname}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialRight(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialRight}
                  onBackdropPress={() => {
                    setVisibleIsSequentialRight(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextData?.unitName === undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 4,
                          fontSize: 14,
                        }}
                      >
                        Thành viên
                      </Text>
                    )}
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {nextData?.name}
                      </Text>
                    {nextData?.unitName != undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                        }}
                      >
                        {nextData?.unitName}
                      </Text>
                    )}
                    </View>
                  </View>
                </Tooltip>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {nextData?.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      Các xử lý tiếp theo
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              </View>
            </View>
          </View>)}
          {!(isPending && isShowRequest) && rightList.data?.length - item?.actionIndex === 3 && (
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingLeft: 0, height: 40, width:47, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage avatar={nextListData?.avatar} name={`${nextListData?.name}`}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialLeft(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialLeft}
                  onBackdropPress={() => {
                    setVisibleIsSequentialLeft(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextListData?.unitName === undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 4,
                          fontSize: 14,
                        }}
                      >
                        Thành viên
                      </Text>
                    )}
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {nextListData?.name}
                      </Text>
                    {nextListData?.unitName != undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                        }}
                      >
                        {nextListData?.unitName}
                      </Text>
                    )}
                    </View>
                  </View>
                </Tooltip>
                <Tooltip
                  style={{ backgroundColor: '#0d91fd', borderColor: '#0d91fd', borderRadius: 6, paddingHorizontal: 4 }}
                  anchor={() => (
                    <Button
                      style={{ borderRadius: 50, paddingHorizontal: 0, height: 40, width:40, }}
                      size="tiny"
                      appearance="file-pact-left"
                      accessoryLeft={
                        <AvatarImage avatar={nextData?.avatar} name={nextData?.name} firstname={nextData?.firstname} lastname={nextData?.lastname}></AvatarImage>
                      }
                      onPress={() => {
                        setVisibleIsSequentialRight(true);
                      }}
                    ></Button>
                  )}
                  visible={visibleIsSequentialRight}
                  onBackdropPress={() => {
                    setVisibleIsSequentialRight(false);
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'column'}}>
                      {nextData?.unitName === undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 4,
                          fontSize: 14,
                        }}
                      >
                        Thành viên
                      </Text>
                    )}
                      <Text
                        style={{
                          color: '#FFFFFF',
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {nextData?.name}
                      </Text>
                    {nextData?.unitName != undefined && (
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                        }}
                      >
                        {nextData?.unitName}
                      </Text>
                    )}
                    </View>
                  </View>
                </Tooltip>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {nextData?.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      Các xử lý tiếp theo
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              </View>
            </View>
          </View>)}
          {!(isPending && isShowRequest) && rightList.data?.length - item?.actionIndex === 2 && (
          <View style={styles.processView}>
            <View style={styles.columnmaxWidth}>
              <View style={styles.rowmaxWidthPb5}>
                <AvatarImage avatar={nextData?.avatar} name={nextData?.name} firstname={nextData?.firstname} lastname={nextData?.lastname}></AvatarImage>
                <View style={styles.columnPl5}>
                  <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                    {nextData?.name}
                  </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                      Xử lý tiếp theo
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              </View>
            </View>
          </View>)}
        </View>
      );
    }
    else {
    if (
      rightList.data?.length > 6 &&
      !isShowMore &&
      index > 0 &&
      ((index < rightList.data?.length - 1 && !isPending) || (index < rightList.data?.length - 2 && isPending))
    )
      return null;
    else if (rightList.data?.length > 6 && !isShowMore && index === rightList.data?.length - 1 && !isPending)
      return (
        <ListItem
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 8,
          }}
          key={index}
          onPress={() => setIsShowMore(true)}
        >
          <MoreIcon></MoreIcon>
          <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
            Hiển thị tiếp {rightList.data?.length - 1} xử lý khác...
          </Text>
        </ListItem>
      );
    else if (rightList.data?.length > 6 && !isShowMore && index === rightList.data?.length - 2 && isPending)
      return (
        <ListItem
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 8,
          }}
          key={index}
          onPress={() => setIsShowMore(true)}
        >
          <MoreIcon></MoreIcon>
          <Text style={{ color: '#787777', fontSize: 15, textAlign: 'right' }}>
            Hiển thị tiếp {rightList.data?.length - 2} xử lý khác...
          </Text>
        </ListItem>
      );
    return (
      <View style={styles.processView} key={index}>
        <View style={styles.columnmaxWidth}>
          <View style={styles.rowmaxWidthPb5}>
            <AvatarImage avatar={item.avatar} name={item.name} firstname={item.firstname} lastname={item.lastname}></AvatarImage>
            <View style={styles.columnPl5}>
              <Text style={{ fontSize: 15, color: '#5b5fc7' }} category="s1">
                {item.name}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 15, color: '#787777', marginTop: 2 }}>
                  {item.process}
                  {item?.tagname && (
                    <View
                      style={{
                        backgroundColor: '#9ca59d',
                        paddingHorizontal: 5,
                        borderRadius: 5,
                        minWidth: 37,
                        alignItems: 'center',
                        marginTop: 2,
                        marginLeft: 7,
                        paddingVertical: 2,
                      }}
                    >
                      <Text style={{ color: '#FFFFFF', fontSize: 13 }}>{item.tagname}</Text>
                    </View>
                  )}
                </Text>
              </View>
            </View>
          </View>
        
          { item.bConclusion === true && (
            <View style={{ flexDirection: 'row', width: '100%', paddingTop: 3, marginLeft: 4 }}>
              {item.process != 'Chờ xử lý' && (
                <Text style={{ lineHeight: 25 }}>{item.conclusion != '' ? item.conclusion : '(Không có diễn giải chi tiết)'}</Text>
              )}
            </View>
          )}
        </View>
      </View>
    );
    }
  };

  const [isShield, setIsShield] = useState<boolean>(false);
  const [isSignModal, setIsSignModal] = useState({ isShowSignModal: false, isShowSignFail: false });
  const [signState, setSignState] = useState({ phoneNumber: '', maKtra: '', soTL: { index: 0, length: 0 }, fileName: '' });

  useEffect(() => {
    if (dataTimeLineProcess === null) return;
    if (selectedIndexView.flag) return;
    selectedIndexView.index = dataTimeLineProcess?.length - 1;
    selectedIndexView.flag = true;
    setSelectedIndexView({ ...selectedIndexView });
  }, [dataTimeLineProcess]);

  useEffect(() => {
    if (!selectedIndexView.flag) return;
    if (dataTimeLineProcess?.at(selectedIndexView.index)?.status === 'done') {
      setLeftList(dataTimeLineProcess?.at(selectedIndexView.index));
      if (isEdit.isTrue && selectedIndexView.index === isEdit.index) {
        isEdit.isShow = true;
        setIsEdit({ ...isEdit });
      } else if (isEdit.isTrue) {
        isEdit.isShow = false;
        setIsEdit({ ...isEdit });
      }
    } else if (dataTimeLineProcess?.at(selectedIndexView.index)?.status === 'pending') {
      if (isEdit.isTrue && selectedIndexView.index === isEdit.index) {
        isEdit.isShow = true;
        setIsEdit({ ...isEdit });
      } else if (isEdit.isTrue) {
        isEdit.isShow = false;
        setIsEdit({ ...isEdit });
      }
    }
  }, [selectedIndexView]);

  const collapseAnim = React.useRef(new Animated.Value(273)).current;

  useEffect(()=>{
    Animated.timing(collapseAnim, {
      toValue: isShowFile? 273: 0,
      duration: 100,
      useNativeDriver: true,
    }).start();
  },[isShowFile])

  return (
    <Layout style={styles.layout} nativeID="layoutPact">
      {isWaiting && (
        <View
          // @ts-ignore
          style={{
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80%',
            width: '100%',
            background: 'white',
            zIndex: 999,
          }}
        >
          <View style={{ top: '-10%' }}>
            <Spinner appearance="ioc" status="primary" />
          </View>
        </View>
      )}
      {isShowProcess && (
        <Layout style={styles.maxLayout}>
          <View style={styles.maxLayout}>
            <View style={styles.layoutRow}>
              <Text
                style={{
                  ...styles.textHeader,
                  fontWeight: pactName?.length > 80 ? '400' : '600',
                  fontSize: pactName?.length > 80 ? 15 : 16,
                }}
                category="s1"
              >
                {pactName}
              </Text>
            </View>
            <Animated.View style={{maxHeight: collapseAnim, overflow:'scroll'}}>
            <View>
            {(isEdit.index != selectedIndexView.index || !isShowRequest) && (
              <View style={styles.headerFileView}>
                {dataTimeLineProcess?.at(selectedIndexView.index).refFileIds?.length > 0 && (
                  <View style={styles.rowCenterPt5}>
                    <View style={{ minWidth: 60 }}>
                      <Text style={styles.labelFile}>Tờ trình </Text>
                    </View>
                    <View style={styles.columnmaxWidthStart}>
                      {dataTimeLineProcess?.at(selectedIndexView.index).refFileIds?.map((itemF, indexF) => (
                        <Button
                          appearance="file-pact"
                          style={{
                            maxWidth: '100%',
                            flexShrink: 1,
                            justifyContent: 'flex-start',
                            borderColor: '#dee2e6',
                            borderRadius: 50,
                            marginVertical: 2.5,
                            marginLeft: isKyThua ? 0 : 7,
                            marginRight: 0,
                          }}
                          size="small"
                          accessoryLeft={
                            dataTimeLineProcess?.at(selectedIndexView.index)?.refFileShield?.at(indexF) ? ShieldFileIcon : getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index).refFileName?.at(indexF))
                          }
                          onPress={() => {
                            downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index).refFileName?.at(indexF));
                          }}
                          key={indexF}
                        >
                          <Text numberOfLines={1} ellipsizeMode="tail">
                            {dataTimeLineProcess?.at(selectedIndexView.index).refFileName?.at(indexF)}
                          </Text>
                        </Button>
                      ))}
                    </View>
                  </View>
                )}
                {dataTimeLineProcess?.at(selectedIndexView.index).refFileIds?.length <= 0 &&
                  selectedIndexView.index === dataTimeLineProcess?.length - 1 &&
                  dataTimeLineProcess?.at(selectedIndexView.index - 1).refFileIds?.length > 0 && (
                    <View style={styles.rowCenterPt5}>
                      <View style={{ minWidth: 60 }}>
                        <Text style={styles.labelFile}>Tờ trình </Text>
                      </View>
                      <View style={styles.columnmaxWidthStart}>
                        {dataTimeLineProcess?.at(selectedIndexView.index - 1).refFileIds?.map((itemF, indexF) => (
                          <Button
                            appearance="file-pact"
                            style={{
                              maxWidth: '100%',
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              marginLeft: isKyThua ? 0 : 7,
                              marginRight: 0,
                            }}
                            size="small"
                            accessoryLeft={
                              dataTimeLineProcess?.at(selectedIndexView.index - 1)?.refFileShield?.at(indexF)
                                ? ShieldFileIcon
                                : getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index - 1).refFileName?.at(indexF))
                            }
                            onPress={() => {
                              downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index - 1).refFileName?.at(indexF));
                            }}
                            key={indexF}
                          >
                            <Text numberOfLines={1} ellipsizeMode="tail">
                              {dataTimeLineProcess?.at(selectedIndexView.index - 1).refFileName?.at(indexF)}
                            </Text>
                          </Button>
                        ))}
                      </View>
                    </View>
                  )}
                {!isKyThua && dataTimeLineProcess?.at(selectedIndexView.index).signFileIds?.length > 0 && (
                  <View style={styles.fileView}>
                    <View style={styles.width60maxHeight}>
                      <View style={styles.Pv5Center}>
                        <Text style={styles.labelFile}>Văn bản </Text>
                      </View>
                    </View>
                    <View style={styles.columnmaxWidthStart}>
                      {dataTimeLineProcess?.at(selectedIndexView.index).signFileIds?.map((itemF, indexF) => (
                        <Button
                          appearance="file-pact"
                          style={{
                            maxWidth: '100%',
                            flexShrink: 1,
                            justifyContent: 'flex-start',
                            borderColor: '#dee2e6',
                            borderRadius: 50,
                            marginVertical: 2.5,
                            marginLeft: 7,
                            marginRight: 0,
                          }}
                          size="small"
                          accessoryLeft={
                            dataTimeLineProcess?.at(selectedIndexView.index)?.signFileShield?.at(indexF) ? ShieldFileIcon : getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index).signFileName?.at(indexF))
                          }
                          onPress={() => {
                            downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index).signFileName?.at(indexF));
                          }}
                          key={indexF}
                        >
                          <Text numberOfLines={1} style={{ width: '100%' }} ellipsizeMode="tail">
                            {dataTimeLineProcess?.at(selectedIndexView.index).signFileName?.at(indexF)}
                          </Text>
                        </Button>
                      ))}
                    </View>
                  </View>
                )}
                {!isKyThua &&
                  dataTimeLineProcess?.at(selectedIndexView.index).signFileIds?.length <= 0 &&
                  selectedIndexView.index === dataTimeLineProcess?.length - 1 &&
                  dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileIds?.length > 0 && (
                    <View style={styles.fileView}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>Văn bản </Text>
                        </View>
                      </View>
                      <View style={styles.columnmaxWidthStart}>
                        {dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileIds?.map((itemF, indexF) => (
                          <Button
                            appearance="file-pact"
                            style={{
                              maxWidth: '100%',
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              marginLeft: 7,
                              marginRight: 0,
                            }}
                            size="small"
                            accessoryLeft={
                              dataTimeLineProcess?.at(selectedIndexView.index - 1)?.signFileShield?.at(indexF)
                                ? ShieldFileIcon
                                : getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileName?.at(indexF))
                            }
                            onPress={() => {
                              downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileName?.at(indexF));
                            }}
                            key={indexF}
                          >
                            <Text numberOfLines={1} style={{ width: '100%' }} ellipsizeMode="tail">
                              {dataTimeLineProcess?.at(selectedIndexView.index - 1).signFileName?.at(indexF)}
                            </Text>
                          </Button>
                        ))}
                      </View>
                    </View>
                  )}
                {isKyThua && isShowRequest && (
                  <View style={styles.fileView}>
                    <View style={styles.maxHeightStart}>
                      <View style={styles.rowCenter}>
                        <View style={styles.width60maxHeight}>
                          <View style={styles.Pv5Center}>
                            <Text style={styles.labelFile}>Văn bản </Text>
                          </View>
                        </View>
                        <Button
                          style={styles.addButton}
                          size="tiny"
                          appearance="ghost"
                          accessoryLeft={AddIcon}
                          onPress={() => uploadNewFile('sign', isEdit)}
                        />
                      </View>
                    </View>
                    {isEdit.action?.signFileIds?.length > 0 && (
                      <View style={styles.columnView}>
                        {isEdit.action?.signFileIds?.map((item, index) => (
                          <View style={styles.rowmaxWidth} key={index}>
                            <Button
                              appearance="file-pact"
                              style={{
                                flexShrink: 1,
                                justifyContent: 'flex-start',
                                borderColor: '#dee2e6',
                                borderRadius: 50,
                                marginVertical: 2.5,
                                paddingRight: 0,
                                marginLeft: 7,
                                marginRight: -7,
                              }}
                              size="small"
                              accessoryLeft={getFileOrFolderIcon(isEdit.action?.signFileName?.at(index))}
                              onPress={() => {
                                downloadFile(item, isEdit.action?.signFileName?.at(index));
                              }}
                              accessoryRight={
                                <Button
                                  appearance="ghost_remove"
                                  style={{ paddingHorizontal: 0 }}
                                  size="tiny"
                                  accessoryLeft={EjectIcon}
                                  onPress={() => {
                                    removeFile('sign', index);
                                  }}
                                ></Button>
                              }
                            >
                              <Text numberOfLines={1} ellipsizeMode="tail">
                                {isEdit.action?.signFileName?.at(index)}
                              </Text>
                            </Button>
                          </View>
                        ))}
                      </View>
                    )}
                    {isEdit.action?.signFileIds?.length <= 0 && (
                      <Button
                        appearance="file-pact"
                        style={{
                          paddingVertical: 1,
                          flexShrink: 1,
                          justifyContent: 'flex-start',
                          borderTopWidth: 0,
                          borderBottomWidth: 0,
                          borderColor: '#dee2e6',
                          borderRadius: 50,
                          marginVertical: 2.5,
                          marginLeft: 7,
                          marginRight: 0,
                        }}
                        size="small"
                      >
                        Không có
                      </Button>
                    )}
                  </View>
                )}
                {dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.length > 0 && (
                  <View style={styles.fileView}>
                    <View style={styles.width60maxHeight}>
                      <View style={styles.Pv5Center}>
                        <Text style={styles.labelFile}>TL khác </Text>
                      </View>
                    </View>
                    <View style={styles.columnmaxWidthStart}>
                      {dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.map((itemF, indexF) => (
                        <Button
                          appearance="file-pact"
                          style={{
                            maxWidth: '100%',
                            flexShrink: 1,
                            justifyContent: 'flex-start',
                            borderColor: '#dee2e6',
                            borderRadius: 50,
                            marginVertical: 2.5,
                            marginLeft: 7,
                            marginRight: 0,
                          }}
                          size="small"
                          accessoryLeft={getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index).supFileName?.at(indexF))}
                          onPress={() => {
                            downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index).supFileName?.at(indexF));
                          }}
                          key={indexF}
                        >
                          <Text numberOfLines={1} style={{ width: '100%' }} ellipsizeMode="tail">
                            {dataTimeLineProcess?.at(selectedIndexView.index).supFileName?.at(indexF)}
                          </Text>
                        </Button>
                      ))}
                    </View>
                  </View>
                )}
                {dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.length <= 0 &&
                  selectedIndexView.index === dataTimeLineProcess?.length - 1 &&
                  dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileIds?.length > 0 && (
                    <View style={styles.fileView}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>TL khác </Text>
                        </View>
                      </View>
                      <View style={styles.columnmaxWidthStart}>
                        {dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileIds?.map((itemF, indexF) => (
                          <Button
                            appearance="file-pact"
                            style={{
                              maxWidth: '100%',
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              marginLeft: 7,
                              marginRight: 0,
                            }}
                            size="small"
                            accessoryLeft={getFileOrFolderIcon(dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileName?.at(indexF))}
                            onPress={() => {
                              downloadFile(itemF, dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileName?.at(indexF));
                            }}
                            key={indexF}
                          >
                            <Text numberOfLines={1} style={{ width: '100%' }} ellipsizeMode="tail">
                              {dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileName?.at(indexF)}
                            </Text>
                          </Button>
                        ))}
                      </View>
                    </View>
                  )}
                {(isShowTL0 || isEdit.isTrue) &&
                  ((dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.length <= 0 && selectedIndexView.index != dataTimeLineProcess?.length - 1) ||
                    (selectedIndexView.index === dataTimeLineProcess?.length - 1 &&
                      dataTimeLineProcess?.at(selectedIndexView.index).supFileIds?.length <= 0 &&
                      dataTimeLineProcess?.at(selectedIndexView.index - 1).supFileIds?.length <= 0)) && (
                    <View style={styles.fileView}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>TL khác </Text>
                        </View>
                      </View>
                      <View style={styles.columnmaxWidthStart}>
                        <Button
                          appearance="file-pact"
                          style={{
                            paddingVertical: 1,
                            flexShrink: 1,
                            justifyContent: 'flex-start',
                            borderTopWidth: 0,
                            borderBottomWidth: 0,
                            borderColor: '#dee2e6',
                            borderRadius: 50,
                            marginVertical: 2.5,
                            marginLeft: 7,
                            marginRight: 0,
                          }}
                          size="small"
                        >
                          Không có
                        </Button>
                      </View>
                    </View>
                  )}
              </View>
            )}
            {isEdit.isTrue && isEdit.index === selectedIndexView.index && isShowRequest && (
              <View style={styles.headerFileView}>
                <View style={styles.rowCenterPt5}>
                  <View style={{ minWidth: 60 }}>
                    <Text style={styles.labelFile}>Tờ trình </Text>
                  </View>
                  <Button
                    style={styles.addButton}
                    size="tiny"
                    appearance="ghost"
                    accessoryLeft={AddIcon}
                    onPress={() => uploadNewFile('ref', isEdit)}
                  />
                  {isEdit.action?.refFileIds?.length > 0 && (
                    <View style={styles.columnView}>
                      {isEdit.action?.refFileIds?.map((item, index) => (
                        <View style={styles.rowmaxWidth} key={index}>
                          <Button
                            appearance="file-pact"
                            style={{
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              paddingRight: 0,
                              marginLeft: 7,
                              marginRight: -7,
                            }}
                            size="small"
                            accessoryLeft={getFileOrFolderIcon(isEdit.action?.refFileName?.at(index))}
                            onPress={() => {
                              downloadFile(item, isEdit.action?.refFileName?.at(index));
                            }}
                            accessoryRight={
                              <Button
                                appearance="ghost_remove"
                                style={{ paddingHorizontal: 0 }}
                                size="tiny"
                                accessoryLeft={EjectIcon}
                                onPress={() => {
                                  removeFile('ref', index);
                                }}
                              ></Button>
                            }
                          >
                            <Text numberOfLines={1} ellipsizeMode="tail">
                              {isEdit.action?.refFileName?.at(index)}
                            </Text>
                          </Button>
                        </View>
                      ))}
                    </View>
                  )}
                  {isEdit.action?.refFileIds?.length <= 0 && (
                    <Button
                      appearance="file-pact"
                      style={{
                        paddingVertical: 1,
                        flexShrink: 1,
                        justifyContent: 'flex-start',
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                        borderColor: '#dee2e6',
                        borderRadius: 50,
                        marginVertical: 2.5,
                        marginLeft: 7,
                        marginRight: 0,
                      }}
                      size="small"
                    >
                      Không có
                    </Button>
                  )}
                </View>

                <View style={styles.fileView}>
                  <View style={styles.maxHeightStart}>
                    <View style={styles.rowCenter}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>Văn bản </Text>
                        </View>
                      </View>
                      <Button
                        style={styles.addButton}
                        size="tiny"
                        appearance="ghost"
                        accessoryLeft={AddIcon}
                        onPress={() => uploadNewFile('sign', isEdit)}
                      />
                    </View>
                  </View>
                  {isEdit.action?.signFileIds?.length > 0 && (
                    <View style={styles.columnView}>
                      {isEdit.action?.signFileIds?.map((item, index) => (
                        <View style={styles.rowmaxWidth} key={index}>
                          <Button
                            appearance="file-pact"
                            style={{
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              paddingRight: 0,
                              marginLeft: 7,
                              marginRight: -7,
                            }}
                            size="small"
                            accessoryLeft={getFileOrFolderIcon(isEdit.action?.signFileName?.at(index))}
                            onPress={() => {
                              downloadFile(item, isEdit.action?.signFileName?.at(index));
                            }}
                            accessoryRight={
                              <Button
                                appearance="ghost_remove"
                                style={{ paddingHorizontal: 0 }}
                                size="tiny"
                                accessoryLeft={EjectIcon}
                                onPress={() => {
                                  removeFile('sign', index);
                                }}
                              ></Button>
                            }
                          >
                            <Text numberOfLines={1} ellipsizeMode="tail">
                              {isEdit.action?.signFileName?.at(index)}
                            </Text>
                          </Button>
                        </View>
                      ))}
                    </View>
                  )}
                  {isEdit.action?.signFileIds?.length <= 0 && (
                    <Button
                      appearance="file-pact"
                      style={{
                        paddingVertical: 1,
                        flexShrink: 1,
                        justifyContent: 'flex-start',
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                        borderColor: '#dee2e6',
                        borderRadius: 50,
                        marginVertical: 2.5,
                        marginLeft: 7,
                        marginRight: 0,
                      }}
                      size="small"
                    >
                      Không có
                    </Button>
                  )}
                </View>

                <View style={styles.fileView}>
                  <View style={styles.maxHeightStart}>
                    <View style={styles.rowCenter}>
                      <View style={styles.width60maxHeight}>
                        <View style={styles.Pv5Center}>
                          <Text style={styles.labelFile}>TL khác </Text>
                        </View>
                      </View>
                      <Button
                        style={styles.addButton}
                        size="tiny"
                        appearance="ghost"
                        accessoryLeft={AddIcon}
                        onPress={() => uploadNewFile('sup', isEdit)}
                      />
                    </View>
                  </View>
                  {isEdit.action?.supFileIds?.length > 0 && (
                    <View style={styles.columnView}>
                      {isEdit.action?.supFileIds?.map((item, index) => (
                        <View style={styles.rowmaxWidth} key={index}>
                          <Button
                            appearance="file-pact"
                            style={{
                              flexShrink: 1,
                              justifyContent: 'flex-start',
                              borderColor: '#dee2e6',
                              borderRadius: 50,
                              marginVertical: 2.5,
                              paddingRight: 0,
                              marginLeft: 7,
                              marginRight: -7,
                            }}
                            size="small"
                            accessoryLeft={getFileOrFolderIcon(isEdit.action?.supFileName?.at(index))}
                            onPress={() => {
                              downloadFile(item, isEdit.action?.supFileName?.at(index));
                            }}
                            accessoryRight={
                              <Button
                                appearance="ghost_remove"
                                style={{ paddingHorizontal: 0 }}
                                size="tiny"
                                accessoryLeft={EjectIcon}
                                onPress={() => {
                                  removeFile('sup', index);
                                }}
                              ></Button>
                            }
                          >
                            <Text numberOfLines={1} ellipsizeMode="tail">
                              {isEdit.action?.supFileName?.at(index)}
                            </Text>
                          </Button>
                        </View>
                      ))}
                    </View>
                  )}
                  {isEdit.action?.supFileIds?.length <= 0 && (
                    <Button
                      appearance="file-pact"
                      style={{
                        paddingVertical: 1,
                        flexShrink: 1,
                        justifyContent: 'flex-start',
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                        borderColor: '#dee2e6',
                        borderRadius: 50,
                        marginVertical: 2.5,
                        marginLeft: 7,
                        marginRight: 0,
                      }}
                      size="small"
                    >
                      Không có
                    </Button>
                  )}
                </View>
              </View>
            )}
            </View>
            </Animated.View>
             
            <ViewPager
              style={{ height: '100%', flexShrink: 1 }}
              selectedIndex={selectedIndexView.index}
              onSelect={(index) => {
                selectedIndexView.index = index;
                setSelectedIndexView({ ...selectedIndexView });
              }}
            >
              {dataTimeLineProcess?.map((item, index) => (
                <ScrollView style={styles.maxLayout1} ref={refScrollView} key={index}>
                  <View style={styles.columnmaxLayout}>
                    
                    <View style={{ maxHeight: '100%' }}>
                      <View style={styles.maxWidthnotShrink}>
                        <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                          <ListItem
                            style={{
                              marginTop: 11.5,
                              marginBottom: 4,
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                              paddingHorizontal: 19,
                              paddingVertical: 4,
                              flexDirection: 'row',
                              flexShrink: 1,
                              backgroundColor: '#5b5fc7',
                            }}
                            key={index}
                            onPress={() => { if (isShowFile) setIsShowFile(false); else if (!isShowFile) setIsShowFile(true)}}   
                          >
                            {isShowCollapse && (
                              <Button style={{paddingHorizontal: 0, marginLeft: -8}} appearance='ghost_add' size='tiny' accessoryLeft={isShowFile ? UpIcon : DownIcon} 
                              onPress={() => { if (isShowFile) setIsShowFile(false); else if (!isShowFile) setIsShowFile(true)}}
                              ></Button>                      
                            )}   
                            <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', flexShrink: 1 }}>
                              {item.flag != -1 && (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    flexShrink: 1,
                                  }}
                                >
                                  <View style={styles.columnCenter}>
                                    <Text style={{ color: 'white', fontSize: 12 }} category="s1">
                                      {item.name?.toUpperCase()}
                                    </Text>
                                  </View>
                                </View>
                              )}
                              {item.flag === -1 && (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    flexShrink: 1,
                                  }}
                                >
                                  <View style={styles.columnCenter}>
                                    <Text style={{ color: 'white', fontSize: 12 }} category="s1">
                                      {item.name?.toUpperCase()}
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </View>
                          </ListItem>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                          paddingTop: 5,
                          justifyContent: 'flex-start',
                        }}
                      >
                        {(index !== dataSpec?.progresses?.length - 1 || item?.status === 'done') && (
                          <View style={styles.alignStartStart}>
                            <List
                              style={{
                                width: '100%',
                                backgroundColor: '#FFFFFF',
                              }}
                              data={item?.actions}
                              renderItem={renderStatus}
                            />
                          </View>
                        )}
                        {index === dataSpec?.progresses?.length - 1 && item?.status === 'pending' && (
                          <View style={styles.alignStartStart}>
                            <List
                              style={{
                                width: '100%',
                                backgroundColor: '#FFFFFF',
                              }}
                              data={rightList.data}
                              renderItem={renderStatusRight}
                            />
                          </View>
                        )}
                      </View>
                      {isShowWait && index === dataSpec?.progresses?.length - 1 && (
                        <View
                          style={{
                            width: '100%',
                            paddingHorizontal: 13,
                            paddingTop: 3,
                            marginLeft: 4,
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Text>{textWait}</Text>
                        </View>
                      )}
                      {isPending && isShowRequest && index === dataSpec?.progresses?.length - 1 && (
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                            paddingTop: 0,
                            paddingHorizontal: 13,
                            alignItems: 'flex-end',
                          }}
                        >
                          {specName != null && (
                            <View style={styles.maxWidthnotShrink}>
                              {((isShowChoose && isXetDuyetTC && isNVB) || isNHS) && (
                                <View style={styles.columnmaxWidth}>
                                  <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Text>Đơn vị nhận</Text>
                                    <Button
                                      appearance="ghost_add"
                                      size="small"
                                      accessoryLeft={LayerIcon}
                                      onPress={() => addDVNState()}
                                    ></Button>
                                  </View>
                                  {isBGD && (
                                    <View
                                      style={{
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        paddingVertical: 5,
                                      }}
                                    >
                                      <Input
                                        textStyle={{ width: 80 }}
                                        style={{
                                          backgroundColor: 'white',
                                          width: '35%',
                                          borderWidth: 1,
                                          color: '#ced4da',
                                          borderRadius: 5,
                                        }}
                                        placeholder="QĐ-ĐHQG"
                                        placeholderTextColor={'#787777'}
                                        multiline={false}
                                        value={AppendixProcess.documentNumber}
                                        onChangeText={(nextValue) => updateAppendix({ documentNumber: nextValue, signatory: null })}
                                      />

                                      <View style={styles.paddingH5}></View>
                                      <Input
                                        textStyle={{ width: 100 }}
                                        style={{
                                          backgroundColor: 'white',
                                          width: '65%',
                                          borderWidth: 1,
                                          color: '#ced4da',
                                          borderRadius: 5,
                                          flexShrink: 1,
                                        }}
                                        placeholder="Họ tên người ký...."
                                        placeholderTextColor={'#787777'}
                                        multiline={false}
                                        value={AppendixProcess.signatory}
                                        onChangeText={(nextValue) => updateAppendix({ documentNumber: null, signatory: nextValue })}
                                      />
                                    </View>
                                  )}
                                  {isIncatationProcess && DVNState?.length > 0 && (
                                    <View style={styles.columnmaxWidth}>
                                      {DVNState?.map((item, index) => (
                                        <View style={styles.rowmaxWidthPv5} key={index}>
                                          <Select
                                            appearance="ioc"
                                            style={{ backgroundColor: 'white', width: '35%' }}
                                            selectedIndex={new IndexPath(DVNState?.at(index)?.index)}
                                            value={ID_TYPE[DVNState?.at(index)?.index]}
                                            onSelect={(indexSelect: IndexPath) => {
                                              DVNState[index].index = indexSelect.row;
                                              if (indexSelect.row === 7) DVNState[index].indexChoose = '';
                                              else DVNState[index].indexChoose = 0;
                                              setDVNState([...DVNState]);
                                            }}
                                          >
                                            <SelectItem appearance="ioc" title="Chọn" />
                                            <SelectItem appearance="ioc" title="BGĐ" />
                                            <SelectItem appearance="ioc" title="BCN" />
                                            <SelectItem appearance="ioc" title="Đoàn" />
                                            <SelectItem appearance="ioc" title="ĐVTV" />
                                            <SelectItem appearance="ioc" title="ĐVTT" />
                                            <SelectItem appearance="ioc" title="Ngoài" />
                                            <SelectItem appearance="ioc" title="Khác" />
                                          </Select>
                                          <View style={styles.paddingH5}></View>
                                          {DVNState?.at(index)?.index != 7 && FormState.isProcess && (
                                            <Select
                                              appearance="ioc"
                                              style={{ backgroundColor: 'white', width: '100%', flexShrink: 1 }}
                                              selectedIndex={new IndexPath(DVNState?.at(index)?.indexChoose)}
                                              value={FormState.CHOOSE[DVNState?.at(index)?.index][DVNState?.at(index)?.indexChoose]}
                                              onSelect={(i: IndexPath) => {
                                                DVNState[index].indexChoose = i.row;
                                                setDVNState([...DVNState]);
                                              }}
                                            >
                                              {FormState.CHOOSE[DVNState?.at(index)?.index]?.map((item, index) => (
                                                <SelectItem appearance="ioc" title={item} key={index} />
                                              ))}
                                            </Select>
                                          )}

                                          {DVNState?.at(index)?.index === 7 && FormState.isProcess && (
                                            <Input
                                              style={{
                                                backgroundColor: 'white',
                                                width: '100%',
                                                flexShrink: 1,
                                                borderWidth: 1,
                                                color: '#ced4da',
                                                borderRadius: 5,
                                              }}
                                              placeholder="Nơi nhận"
                                              placeholderTextColor={'#787777'}
                                              multiline={false}
                                              value={DVNState?.at(index)?.indexChoose}
                                              onChangeText={(nextValue) => {
                                                DVNState[index].indexChoose = nextValue;
                                                setDVNState([...DVNState]);
                                              }}
                                            />
                                          )}
                                          {index != 0 && (
                                            <View
                                              style={{
                                                width: 30,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: 'white',
                                                position: 'absolute',
                                                right: 3,
                                                top: 20,
                                                bottom: 20,
                                              }}
                                            >
                                              <Button
                                                appearance="ghost"
                                                size="small"
                                                accessoryLeft={EjectSignIcon}
                                                onPress={() => removeDVState(index)}
                                              ></Button>
                                            </View>
                                          )}
                                        </View>
                                      ))}
                                    </View>
                                  )}
                                </View>
                              )}
                              {isTravel && isXetDuyetTC && (
                                <View style={{ width: '100%', paddingTop: 5 }}>
                                  <Select
                                    appearance="ioc"
                                    style={{ backgroundColor: 'white' }}
                                    selectedIndex={selectedIndex}
                                    value={displayValue}
                                    onSelect={(index) => setSelectedIndex(index)}
                                  >
                                    <SelectItem appearance="ioc" title="Chọn hướng xử lý" />
                                    <SelectItem appearance="ioc" title="Xét duyệt TC" />
                                    <SelectItem appearance="ioc" title="LĐ.VP duyệt" />
                                  </Select>
                                </View>
                              )}
                              <View style={{ width: '100%', paddingVertical: 7 }}>
                                <Input
                                  style={{ backgroundColor: 'white', borderWidth: 1, color: '#ced4da', borderRadius: 5 }}
                                  multiline={true}
                                  textStyle={{ minHeight: 60 }}
                                  placeholder={placeholderText}
                                  placeholderTextColor={'#787777'}
                                  value={conclusion}
                                  onChangeText={(nextValue) => setConclusion(nextValue)}
                                />
                              </View>
                              {waiting && (
                                <View
                                  style={{
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    flexDirection: 'row',
                                    paddingVertical: 13,
                                  }}
                                >
                                  <View
                                    style={{
                                      paddingHorizontal: 5,
                                      backgroundColor: '#5b5fc7',
                                      borderRadius: 3,
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <ActivityIndicator style={styles.loadingIcon} size="small" color="#FFFFFF" />
                                    <Text style={{ color: '#FFFFFF', paddingVertical: 7, paddingHorizontal: 5, fontSize: 13 }}>
                                      Đang xử lý...
                                    </Text>
                                  </View>
                                </View>
                              )}
                              {!waiting && (
                                <View
                                  style={{
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    flexDirection: 'row',
                                    paddingVertical: 13,
                                  }}
                                >
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'sign') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#5b5fc7', borderColor: '#5b5fc7' }}
                                        size="tiny"
                                        accessoryLeft={
                                          isShield ? <ShieldIcon name="shield-checkmark-sharp" color="#FFFFFF"></ShieldIcon> : null
                                        }
                                        onPress={() => {
                                          OnClick('sign');
                                        }}
                                      >
                                        Ký Duyệt
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'confirm') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#198754', borderColor: '#198754' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('confirm');
                                        }}
                                      >
                                        Xác nhận
                                      </Button>
                                    </View>
                                  )}

                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'forward') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#FFFFFF', borderColor: '#6c757d' }}
                                        size="tiny"
                                        appearance="outline"
                                        status="basic"
                                        onPress={() => {
                                          OnClick('');
                                        }}
                                      >
                                        Chỉ đạo
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'end') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('');
                                        }}
                                      >
                                        Đã xem
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'approve') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#198754', borderColor: '#198754' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('approve');
                                        }}
                                      >
                                        Duyệt
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'submit') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#198754', borderColor: '#198754' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('submit');
                                        }}
                                      >
                                        Gửi
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'reject') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#ffc107', borderColor: '#ffc107' }}
                                        size="tiny"
                                        onPress={() => {
                                          OnClick('reject');
                                        }}
                                      >
                                        Từ chối
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'reset') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#FFFFFF', borderColor: '#8f9bb3' }}
                                        size="tiny"
                                        status="basic"
                                        appearance="ghost"
                                        onPress={() => {
                                          OnClick('reset');
                                        }}
                                      >
                                        YC Hiệu chỉnh
                                      </Button>
                                    </View>
                                  )}
                                  {rightList.data.find(
                                    (action) =>
                                      action.validDirections.find((direction) => direction === 'cancel') != undefined &&
                                      action.flagProcess === true
                                  ) != undefined && isShowCancel && (
                                    <View style={styles.paddingH5}>
                                      <Button
                                        style={{ backgroundColor: '#FFFFFF', borderColor: '#ff3d71' }}
                                        size="tiny"
                                        status="danger"
                                        appearance="outline"
                                        onPress={() => {
                                          OnClick('cancel');
                                        }}
                                      >
                                        {isEdit.isTrue ? 'Hủy' : 'Trả hồ sơ'}
                                      </Button>
                                    </View>
                                  )}
                                </View>
                              )}
                            </View>
                          )}
                        </View>
                      )}
                    </View>
                    <View style={{ height: '100%', flexShrink: 1 }}></View>
                  </View>
                </ScrollView>
              ))}
            </ViewPager>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: 5,
                position: 'absolute',
                bottom: 0,
              }}
            >
              {dataTimeLineProcess?.map((item, index) => (
                <View style={{ padding: 2 }} key={index}>
                  <FlagIcon check={index === selectedIndexView.index ? 'true' : 'false'}></FlagIcon>
                </View>
              ))}
            </View>
            <View style={{ height: 26 }}></View>
          </View>
        </Layout>
      )}
      <Modal
        visible={isSignModal.isShowSignModal}
        backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        style={{ padding: 0, width: '100%', paddingHorizontal: 10, paddingBottom: screen.height * 0.25 }}
      >
        <Layout
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            borderRadius: 3,
            overflow: 'hidden',
            flexGrow: 1,
            borderWidth: 0,
            width: '100%',
          }}
        >
          <Layout style={{ flex: 6, paddingHorizontal: 10, paddingVertical:8, backgroundColor: '#2c3a47', flexDirection: 'row', alignItems: 'center' }}>
            <ShieldIconTitle></ShieldIconTitle>
            <View style={{ paddingHorizontal: 2 }}></View>
            <Text style={{ color: '#FFFFFF' }}>KÝ SỐ VĂN BẢN</Text>
          </Layout>
          <Layout style={{ flex: 6, padding: 10, backgroundColor: '#FFFFFF', flexDirection: 'row' }}>
            <Text>
              Yêu cầu ký số đã được gửi đến SIM <Text style={{ color: '#0969da' }}>{signState.phoneNumber}</Text>
            </Text>
          </Layout>
          <Layout style={{ flex: 6, padding: 10, backgroundColor: '#FFFFFF', flexDirection: 'row' }}>
            <Text style={{ fontWeight: '600' }}>{signState.soTL.index + 1}</Text>
            <Text>/{signState.soTL.length} File: </Text>
            <View style={{ paddingHorizontal: 2 }}></View>
            <Text style={{ fontWeight: '600' }}>{signState.fileName}</Text>
          </Layout>
          <Divider style={styles.dividerline} />
          {!isSignModal.isShowSignFail && (
            <Layout
              style={{
                flex: 6,
                padding: 10,
                backgroundColor: '#FFFFFF',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text>Xin vui lòng xác nhận trên SIM</Text>
              <View style={{ width: 40 }}>
                <ActivityIndicator style={styles.loadingIcon} size="small" color="#5b5fc7" />
              </View>
            </Layout>
          )}
          {isSignModal.isShowSignFail && (
            <Layout
              style={{
                flex: 6,
                padding: 10,
                backgroundColor: '#FFFFFF',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text>Lỗi: không nhận được phản hồi</Text>
              <Button
                size="tiny"
                onPress={() => {
                  isSignModal.isShowSignFail = false;
                  isSignModal.isShowSignModal = false;
                  setIsSignModal({ ...isSignModal });
                }}
              >
                OK
              </Button>
            </Layout>
          )}
        </Layout>
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    color: '#424242',
    margin: 0,
    fontSize: 16,
    lineHeight: 22,
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconHeader: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProcess: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCirle: {
    display: 'flex',
    fontSize: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layoutRow: {
    display: 'flex',
    paddingTop: 8,
    marginTop: 5,
    marginBottom: 2,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 13,
    paddingRight: 13,
  },
  layoutBorder: {
    display: 'flex',
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EAECF0',
  },
  layoutRowHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  layoutColumn: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexShrink: 1,
  },
  layoutRowLeft50: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutColumnLeft50: {
    display: 'flex',
    width: '50%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter20: {
    display: 'flex',
    width: '20%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  layoutCenter30: {
    display: 'flex',
    width: '30%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  addButton: {
    minWidth: 40,
    maxWidth: 40,
    minHeight: 27,
    maxHeight: 27,
    paddingHorizontal: 8,
    flexShrink: 1,
    justifyContent: 'center',
    borderColor: '#dee2e6',
    borderRadius: 50,
    marginVertical: 2.5,
  },
  maxLayout: {
    flexShrink: 1,
    width: '100%',
    height: '100%',
  },
  layout: {
    flex: 1,
    flexDirection: 'column',
  },
  dividerline: {
    width: '100%',
    border: 1,
    borderColor: '#424242',
    height: 1,
  },
  headerFileView: {
    paddingLeft: 13,
    paddingRight: 13,
    marginRight: 7,
    flexDirection: 'column',
    paddingBottom: 5,
  },
  fileView: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 2,
  },
  columnmaxWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  rowmaxWidthPb5: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 5,
    width: '100%',
  },
  columnPl5: {
    flexDirection: 'column',
    paddingLeft: 7,
  },
  rowCenterPt5: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 2,
  },
  columnView: {
    flexDirection: 'column',
    flexShrink: 1,
  },
  columnmaxWidthStart: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    flexShrink: 1,
  },
  width60maxHeight: {
    minWidth: 60,
    alignItems: 'flex-start',
    height: '100%',
  },
  Pv5Center: {
    paddingVertical: 7,
    alignItems: 'center',
  },
  menuIcon: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxHeightStart: {
    alignItems: 'flex-start',
    height: '100%',
  },
  rowCenter: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  rowmaxWidth: {
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  columnmaxLayout: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flexShrink: 1,
  },
  maxLayout1: {
    height: '100%',
    width: '100%',
  },
  paddingH5: {
    paddingLeft: 10,
  },
  alignStartStart: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  maxWidthnotShrink: {
    width: '100%',
  },
  columnCenter: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowmaxWidthPv5: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 5,
  },
  labelFile: {
    fontStyle: 'italic',
  },
  processView: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 13,
    paddingVertical: 8,
  },
  loadingIcon: {
    transform: [{ scaleX: 0.6 }, { scaleY: 0.6 }],
  },
});
